import styled from "styled-components";

export const TutorialContainer = styled.fieldset`
  /* width: 60%;
  margin: 0 auto; */

  h3 {
    text-align: center;
  }

  .btn-container {
    display: flex;
  }
`;

export const WALoginBtn = styled.button`
  background-color: #1877f2;
  border: 0;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  height: 40px;
  padding: 0 24px;
  transition: all .2s ease-in-out;

  &:hover {
    transform: translateY(-2px)
  }
`;
