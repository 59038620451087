import QRCode from 'qrcode.react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import NotificationAlert from 'react-notification-alert';
import {
    Alert, Badge, Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input,
    Label, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner
} from 'reactstrap';

import { MenuComportamento } from '../../../../../../components/Menus';
import EmpresaContext from '../../../../../../contexts/Empresa';
import api from '../../../../../../services/api';
import WhatsAppIcon from '../../../../../marketing/Chat/components/Icons/whatsapp';
import WhatsAppUserIcon from '../../../../../marketing/Chat/components/Icons/whatsapp-user';
import WhatsAppVerifiedIcon from '../../../../../marketing/Chat/components/Icons/whatsapp-verified';
import { IAccount } from '../../../../../marketing/Chat/types';
import AddEquipeModal from './components/AddEquipeModal';
import { TutorialContainer } from './components/styles';
import WhatsAppLoginButton from './components/WhatsAppLoginButton';

interface AccountsList {
  hideTab: () => void;
  accounts: IAccount[];
  onSuccess: () => Promise<void>;
}

interface ClientInfoResponse {
  success: boolean;
  sessionInfo: {
    pushname: string;
    wid: {
      server: string;
      user: string;
      _serialized: string;
    };
    me: {
      server: string;
      user: string;
      _serialized: string;
    };
    platform: string;
  };
}

function formatPhoneNumber(phoneNumber: string | undefined) {
  if (!phoneNumber) return;
  // Adiciona o "+" no começo, os dois primeiros dígitos são o código do país
  const countryCode = `+${phoneNumber.slice(0, 2)}`;

  // Os próximos dois dígitos são o código de área
  const areaCode = phoneNumber.slice(2, 4);

  // Verifica se o número tem o '9' após o DDD
  const hasNine = phoneNumber[4] === '9';

  // Se tiver o '9', considera essa parte ao dividir o número
  const firstPart = hasNine ? phoneNumber.slice(5, 9) : phoneNumber.slice(4, 8);
  const secondPart = hasNine
    ? phoneNumber.slice(9, 13)
    : phoneNumber.slice(8, 12);

  // Formata o número final
  return `${countryCode} ${areaCode} ${
    hasNine ? '9 ' : ''
  }${firstPart}-${secondPart}`;
}

export default function AccountsListGroup({
  hideTab,
  accounts,
  onSuccess,
}: AccountsList) {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const notificationAlert = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [accountSelecionada, setAccountSelecionada] = useState<IAccount | null>(
    null
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [identificacao, setIdentificacao] = useState('');
  const [isSessionActive, setIsSessionActive] = useState(false);
  const [modalWhatsApp, setModalWhatsApp] = useState(false);
  const [isModalAccountVisible, setIsModalAccountVisible] = useState(false);
  const [alert, setAlert] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [APIMetaModal, setAPIMetaModal] = useState(false);
  const [QRCodeString, setQRCodeString] = useState('');
  const [sessionID, setSessionID] = useState('');

  const modalOpenRef = useRef(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  function openModal(account: IAccount) {
    setIsModalOpen(true);
    setAccountSelecionada(account);
  }

  function openAccountModal(account: IAccount) {
    setIsModalAccountVisible(true);
    setAccountSelecionada(account);
  }

  function closeAccountModal() {
    setIsModalAccountVisible(false);
    setAccountSelecionada(null);
  }

  function toggleAPIMetaModal() {
    setAPIMetaModal((prevState) => !prevState);
  }

  function closeModal() {
    setIsModalOpen(false);
    setAccountSelecionada(null);
  }

  function toggleModal() {
    setIsModalOpen((prevState) => !prevState);
  }

  function launchWhatsAppSignup() {
    //@ts-ignore
    FB.login(
      function (response) {
        if (response.authResponse) {
          const code = response.authResponse.code;
          api
            .post('/meta/access-token', { code })
            .then((res) => onSuccess())
            .catch((err) => console.error(err));
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      },
      {
        config_id: process.env.REACT_APP_FB_LOGIN_CONFIG_ID,
        response_type: 'code', // must be set to 'code' for System User access token
        override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
      }
    );
  }

  function notify(type: string, msg: string) {
    let options = {
      place: 'tc',
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: 'ni ni-bell-55',
      autoDismiss: 7,
    };
    if (notificationAlert?.current)
      //@ts-ignore
      notificationAlert.current.notificationAlert(options);
  }

  function handleModalWhatsAppOpen() {
    modalOpenRef.current = true;
    setModalWhatsApp(true);
  }

  function handleModalWhatsAppClose() {
    modalOpenRef.current = false;
    setModalWhatsApp(false);
    setQRCodeString('');
  }

  async function startSession() {
    try {
      const { data } = await api.get(`/meta/start`, {
        timeout: 10000,
      });
      setSessionID(data.id);
      return data;
    } catch (error) {
      setAlert(
        <ReactBSAlert
          warning
          style={{ display: 'block' }}
          title="Erro"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="primary"
          confirmBtnText="Ok"
          btnSize=""
        >
          Ocorreu um erro ao tentar gerar o QR code. Tente novamente em alguns
          minutos.
        </ReactBSAlert>
      );
      console.error(error);
    }
  }

  async function fetchQRCode() {
    let isGenerating = true;
    let counter = 1;

    setIsLoading(true);

    const session = await startSession();

    while (
      isGenerating &&
      counter <= 10 &&
      session &&
      session.id &&
      modalOpenRef.current
    ) {
      try {
        const { data } = await api.get(
          `/meta/qr/image?session_id=${session.id}`,
          {
            timeout: 5000, // Timeout de 5 segundos
          }
        );

        if (data.success) {
          setQRCodeString(data.qr);
          isGenerating = false;
        } else if (counter === 10) {
          setAlert(
            <ReactBSAlert
              warning
              style={{ display: 'block' }}
              title="Erro"
              onConfirm={() => setAlert(null)}
              onCancel={() => setAlert(null)}
              confirmBtnBsStyle="primary"
              confirmBtnText="Ok"
              btnSize=""
            >
              Ocorreu um erro ao tentar gerar o QR code. Tente novamente em
              alguns minutos.
            </ReactBSAlert>
          );
        } else {
          // Aguarda 5 segundos antes de tentar novamente
          await new Promise((resolve) => setTimeout(resolve, 5000));
          counter++;
        }
      } catch (error) {
        console.error('Erro na requisição:', error);
        counter++;

        // Aguarda 5 segundos antes de tentar novamente após um erro
        await new Promise((resolve) => setTimeout(resolve, 5000));
      }
    }

    setIsLoading(false);
  }

  async function getClientInfo(sessionId: string) {
    try {
      const {
        data: { success },
      } = await api.get(`/meta/status?session_id=${sessionId}`);

      if (success) {
        const {
          data: { sessionInfo },
        } = await api.get<ClientInfoResponse>(
          `/meta/class-info?session_id=${sessionId}`
        );

        const {
          wid: { user: numeroConectado },
        } = sessionInfo;

        const { pushname } = sessionInfo;
        // Se os dados da sessão existirem, registra a conta no sistema
        const { data } = await api.post(`/meta/accounts/web`, {
          account: {
            verified_name: pushname,
            code_verification_status: 'VERIFIED',
            display_phone_number: numeroConectado,
            quality_rating: 'GREEN',
            id: numeroConectado,
            session_id: sessionID,
          },
        });
        if (data.success) {
          // Exibe o alerta de sucesso e fecha o modal
          setAlert(
            <ReactBSAlert
              success
              style={{ display: 'block' }}
              title="Sucesso!"
              onConfirm={() => setAlert(null)}
              onCancel={() => setAlert(null)}
              confirmBtnBsStyle="primary"
              confirmBtnText="Ok"
              btnSize=""
            >
              Número sincronizado com sucesso!
            </ReactBSAlert>
          );
        } else {
          setAlert(
            <ReactBSAlert
              warning
              style={{ display: 'block' }}
              title="Erro!"
              onConfirm={() => setAlert(null)}
              onCancel={() => setAlert(null)}
              confirmBtnBsStyle="primary"
              confirmBtnText="Ok"
              btnSize=""
            >
              {data.message}
            </ReactBSAlert>
          );
        }

        setModalWhatsApp(false); // Fechar o modal
        setQRCodeString('');
        onSuccess();
        // Retorna verdadeiro para parar o polling
        return true;
      }
    } catch (error) {
      console.error('Erro ao obter informações do cliente:', error);
    }

    // Retorna falso para continuar o polling
    return false;
  }

  async function unsubscribeAccount(account: IAccount) {
    if (account.api_meta) {
      // Cancelar a assinatura da WABA
    } else {
      setAlert(
        <ReactBSAlert
          danger
          title="Desativar número"
          onConfirm={() =>
            terminateWhatsAppSession({
              sessionId: account?.session_id,
              accountId: account.id,
            })
          }
          onCancel={() => setAlert(null)}
          showCancel
          disabled={isLoading}
          confirmBtnBsStyle="primary"
          confirmBtnText="Sim"
          cancelBtnBsStyle="link"
          cancelBtnText="Não"
          btnSize=""
        >
          Tem certeza que deseja desativar esse número de WhatsApp?
        </ReactBSAlert>
      );
    }
  }

  async function terminateWhatsAppSession({ sessionId, accountId }) {
    await api.post(`/meta/terminate`, {
      session_id: sessionId,
      account_id: accountId,
    });

    onSuccess();
    setAlert(null);
  }

  async function handleAccountUpdate() {
    try {
      if (accountSelecionada) {
        await api.put(`/meta/accounts/${accountSelecionada?.id}`, {
          nome: identificacao,
        });

        onSuccess();
        closeAccountModal();
        setAlert(
          <ReactBSAlert
            success
            style={{ display: 'block' }}
            title="Sucesso!"
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="primary"
            confirmBtnText="Ok"
            btnSize=""
          >
            Conta atualizada com sucesso!
          </ReactBSAlert>
        );
      }
    } catch (error) {
      console.error(error);
      notify('danger', 'Não foi possível atualizar os dados da conta.');
    }
  }

  useEffect(() => {
    if (accountSelecionada) {
      setIdentificacao(accountSelecionada.nome);
    }
  }, [accountSelecionada]);

  useEffect(() => {
    let pollingInterval: NodeJS.Timeout | undefined;

    if (!modalWhatsApp && pollingInterval) {
      clearInterval(pollingInterval);
    }

    if (QRCodeString && sessionID && modalWhatsApp) {
      // Quando a imagem do QR Code for exibida, inicia o polling
      pollingInterval = setInterval(async () => {
        const isClientSynced = await getClientInfo(sessionID);

        if (isClientSynced) {
          // Se a conta for sincronizada, para o polling
          clearInterval(pollingInterval);
        }
      }, 3000);
    }

    // Limpa o polling se o componente for desmontado
    return () => {
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    };
  }, [QRCodeString, sessionID, modalWhatsApp]);

  return (
    <>
      {alert}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Modal isOpen={APIMetaModal} size="lg" toggle={toggleAPIMetaModal}>
        <ModalHeader toggle={toggleAPIMetaModal}>Integrar via API Meta</ModalHeader>
        <ModalBody>
          <TutorialContainer>
            <legend>Atenção aos passos necessários</legend>
            <p>
              1. Integre usa conta do Facebook ao Playnee clicando no botão
              "Iniciar Integração" e siga as instruções do Facebook.
            </p>
            <p>
              2. Uma vez realizada a integração, o Playnee irá listar o(s)
              número(s) de Whatsapp integrado(s). Observe que ao lado de cada
              número listado você encontrará o botão para adicionar as equipes
              responsáveis pelo atendimento ao número.
            </p>
            <div className="mb-2">
              <span>Botão para adicionar as equipes:</span>
              <Button size="sm" className="ml-1" color="primary">
                Adicionar Equipes
              </Button>
            </div>
            <p>
              Essa informação é importante pois é a partir dela que saberemos
              para quem entregar os clientes que entram em contado com sua
              empresa por meio do número WhatsApp.
            </p>
            <Alert color="primary text-center">
              Observação: é necessário criar equipes de captação/atendimento
              antes de executar esta ação.
            </Alert>
          </TutorialContainer>
        </ModalBody>
        <ModalFooter>
          <Button outline onClick={toggleAPIMetaModal}>
            Voltar
          </Button>

          <Button
            color="primary"
            onClick={() => {
              launchWhatsAppSignup();
              toggleAPIMetaModal();
            }}
          >
            Iniciar Integração
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalWhatsApp} size="lg" toggle={handleModalWhatsAppClose}>
        <ModalHeader toggle={handleModalWhatsAppClose}>Integrar via WhatsApp Web</ModalHeader>
        <ModalBody>
          {isLoading && (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ gap: '1rem' }}
            >
              <span>Gerando seu QR Code...</span>
              <Spinner color="primary" size="sm" />
            </div>
          )}
          {QRCodeString && (
            <div className="text-center mb-3">
              <QRCode size={256} value={QRCodeString} />
            </div>
          )}
          <TutorialContainer>
            <legend>Atenção aos passos necessários</legend>
            <p>
              1. Para iniciar o processo, clique no botão{' '}
              <strong>Gerar QR Code</strong>.
            </p>
            <p>
              2. Abra o WhatsApp do seu celular e toque em{' '}
              <strong>mais opções</strong> no Android ou em{' '}
              <strong>configurações</strong> no IPhone.
            </p>
            <p>
              3. Toque em <strong>Dispositivos Conectados</strong> e, em
              seguida, em <strong>Conectar Dispositivo</strong>.
            </p>
            <p>
              4. Aponte seu celular para o QR code gerado nesta caixa e aguarde
              o código ser escaneado.
            </p>
            <p>
              5. Uma vez realizada a integração, o Playnee irá listar o(s)
              número(s) de Whatsapp integrado(s). Observe que ao lado de cada
              número listado você encontrará o botão para adicionar as equipes
              responsáveis pelo atendimento ao número.
            </p>
            <div className="mb-2">
              <span>Botão para adicionar as equipes:</span>
              <Button size="sm" className="ml-1" color="primary">
                Adicionar Equipes
              </Button>
            </div>
            <p>
              Essa informação é importante pois é a partir dela que saberemos
              para quem entregar os clientes que entram em contado com sua
              empresa por meio do número WhatsApp.
            </p>
            <Alert color="primary text-center">
              Observação: é necessário criar equipes de captação/atendimento
              antes de executar esta ação.
            </Alert>
          </TutorialContainer>
        </ModalBody>
        <ModalFooter>
          <Button outline onClick={handleModalWhatsAppClose}>
            Voltar
          </Button>

          {!isSessionActive && (
            <Button color="primary" onClick={fetchQRCode}>
              Gerar QR Code
            </Button>
          )}
        </ModalFooter>
      </Modal>

      <Modal isOpen={isModalAccountVisible} toggle={closeAccountModal}>
        <ModalHeader>Editar Conta</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label className="form-control-label" for="nomeInp">
              Nome
            </Label>
            <Input disabled value={accountSelecionada?.verified_name} />
          </FormGroup>
          <FormGroup>
            <Label className="form-control-label" for="nomeInp">
              Número
            </Label>
            <Input
              disabled
              value={formatPhoneNumber(accountSelecionada?.phone_number)}
            />
          </FormGroup>
          <FormGroup>
            <Label className="form-control-label" for="nomeInp">
              Identificação
            </Label>
            <Input
              onChange={(event) => setIdentificacao(event.target.value)}
              value={identificacao}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="link" onClick={closeAccountModal}>
            Voltar
          </Button>
          <Button color="primary" onClick={handleAccountUpdate}>
            Salvar
          </Button>
        </ModalFooter>
      </Modal>

      <AddEquipeModal
        close={closeModal}
        show={isModalOpen}
        empresaId={empresaSelecionada?.id}
        accountId={accountSelecionada?.id}
      />

      <div
        style={{
          flex: 1,
        }}
        className="mb-4 d-flex align-items-center justify-content-between"
      >
        <Button color="secondary" outline onClick={hideTab}>
          Voltar
        </Button>

        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle size="sm" color="primary">
            Integrar com o WhatsApp
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={toggleAPIMetaModal}>
              Via API Meta
            </DropdownItem>
            <DropdownItem onClick={handleModalWhatsAppOpen}>
              Via WhatsApp Web
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>

      {accounts.length > 0 && (
        <ListGroup className="list mt-4" flush>
          <h3>Números Conectados</h3>
          {accounts.map((account, key) => (
            <ListGroupItem
              className="list-group-item-action px-0"
              style={{
                flexDirection: 'row',
                display: 'flex',
                flex: 1,
                alignItems: 'center',
              }}
              key={key}
            >
              <div className="col-auto">
                <MenuComportamento
                  direction="right"
                  actions={[
                    {
                      label: 'Editar',
                      icon: 'fas fa-edit',
                      onClick: () => openAccountModal(account),
                    },
                    {
                      label: 'Remover',
                      icon: 'fas fa-trash',
                      onClick: () => unsubscribeAccount(account),
                    },
                  ]}
                />
              </div>
              <Row style={{ flex: 1 }} className="align-items-center">
                <div className="col">
                  <h4 className="mb-0">
                    <span>
                      {account.api_meta ? (
                        <WhatsAppVerifiedIcon />
                      ) : account.user_id ? (
                        <WhatsAppUserIcon />
                      ) : (
                        <WhatsAppIcon heigth={26} width={26} />
                      )}
                    </span>
                    <span className="ml-1">{account.phone_number}</span>
                    <span>
                      {' | '}
                      {account.verified_name}
                      {account.nome && ` | ${account.nome}`}
                    </span>
                    {account.situacao == 'I' && (
                      <Badge pill color="warning" className="ml-2">
                        Desconectado
                      </Badge>
                    )}
                  </h4>
                </div>
              </Row>
              <Button
                className="btn-sm"
                color="primary"
                onClick={() => openModal(account)}
              >
                Adicionar Equipes
              </Button>
            </ListGroupItem>
          ))}
        </ListGroup>
      )}
    </>
  );
}
