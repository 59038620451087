import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import { IAccount, IContact } from '../pages/marketing/Chat/types';
import IMessage from '../entities/Meta/message';
import WebSocketClient from '../pages/marketing/Chat/AdonisWSController';
import EmpresaContext from '../contexts/Empresa';
import AuthContext from '../contexts/Auth';
import api from '../services/api';

import { Oportunidade } from '../entities/Marketing';

type UserEmpresa = {
  id: number;
  user_id: number;
  empresa_id: number;
  situacao: string | null;
  equipe_id: number | null;
};

type User = {
  id: number;
  name: string;
  status: boolean;
  avatar: string;
  situacao: string | null;
  userEmpresas: UserEmpresa[];
};

enum typeEnum {
  SINGLE = 'SINGLE',
  HYDRATATION = 'HYDRATATION',
  STATUS = 'STATUS',
  PARTIAL = 'PARTIAL',
}

interface IPayload {
  SINGLE: IContact;
  HYDRATATION: IContact[];
  PARTIAL: IContact[];
  STATUS: IContact;
  TYPE: typeEnum;
  serverTime?: string;
}
interface IMessagePayload {
  SINGLE: IMessage;
  HYDRATATION: IMessage[];
  STATUS: IMessage;
  TYPE: typeEnum;
}

interface ContactsPayload {
  initial_date: Date;
  empresa_id: number;
  user_id: number | null;
  account_id: string;
}

interface IWhatsappContext {
  contacts: IContact[];
  messages: Map<string, IMessage[]>;
  selectedContact: IContact | null;
  updateContacts: (newContacts: IContact[]) => void;
  selectContact: (contact: IContact | null) => void;
  sendMessage: (topic: string, message: any, event?: string) => void;
  selecionarOportunidade: (oportunidade: Oportunidade | null) => void;
  oportunidadeSelecionada: Oportunidade | null;
  serverTime: string;
  colaboradores: User[];
  usuarioSelecionado: User | null;
  selecionarColaborador: (userid: number) => void;
  accounts: IAccount[];
  selectAccount: (id: string) => void;
  selectedAccount: IAccount | null;
  colaboradorSelecionadoId: number | null;
  connectionHasError: boolean;
  contatoTemOportunidade: boolean | undefined;
  atualizarContatos: () => void;
  sendTemplateMessage: (mensagemTemplate: any, destinatario: any) => void;
  loadMoreContacts: (payload: ContactsPayload) => void;
}

const initialContext: IWhatsappContext = {
  contacts: [],
  messages: new Map(),
  selectedContact: null,
  updateContacts: () => {
    console.log('-------initialContext');
  },
  selectContact: () => {},
  sendMessage: () => {},
  selecionarOportunidade: () => {},
  oportunidadeSelecionada: null,
  serverTime: '',
  colaboradores: [],
  selecionarColaborador: (userId: number) => {},
  selectAccount: (id: string) => {},
  accounts: [],
  selectedAccount: null,
  colaboradorSelecionadoId: null,
  connectionHasError: false,
  contatoTemOportunidade: false,
  atualizarContatos: () => {},
  usuarioSelecionado: null,
  sendTemplateMessage: (mensagemTemplate, destinatario) => {},
  loadMoreContacts: (payload: ContactsPayload) => {}
};

export const WhatsappContext = createContext<IWhatsappContext>(initialContext);

export const WhatsappProvider: React.FC = ({ children }) => {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const { signed, user, hasRole } = useContext(AuthContext);

  const [colaboradorSelecionadoId, setColaboradorSelecionadoId] =
    // @ts-ignore
    useState<number>(() => user.id);
  const [colaboradores, setColaboradores] = useState<User[]>([]);
  const [usuarioSelecionado, setUsuarioSelecionado] = useState<User | null>(
    null
  );
  const [connectionHasError, setConnectionHasError] = useState(false);
  const [templates, setTemplates] = useState<any>([]);
  const [isConnected, setIsConnected] = useState(true);

  const [contacts, setContacts] = useState<IContact[]>([]);
  const [selectedContact, setSelectedContact] = useState<IContact | null>(null);

  const [accounts, setAccounts] = useState<IAccount[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<IAccount | null>(null);

  const [serverTime, setServerTime] = useState<string>('');
  const [oportunidadeSelecionada, setOportunidadeSelecionada] =
    useState<Oportunidade | null>(null);
  const webSocketClientRef = useRef<WebSocketClient | null>(
    new WebSocketClient()
  );
  const [messages, setMessages] = useState<Map<string, IMessage[]>>(new Map());
  const [hasPermission, setHasPermission] = useState(() =>
    hasRole('gestor_comercial')
  );
  const selectedContactRef = useRef<IContact | null>(null);

  let isMount = false;
  let webSocketInitialized = false;

  useEffect(() => {
    isMount = true;
    if (
      !empresaSelecionada ||
      !signed ||
      webSocketInitialized ||
      !accounts.length
    )
      return;

    const webSocketClient = new WebSocketClient();
    webSocketClientRef.current = webSocketClient;
    const handleWebSocketOpen = () => {
      webSocketInitialized = true;
      console.log('WebSocket connection established');

      if (accounts.length === 1) {
        const singleAccount = accounts[0];
        setSelectedAccount(singleAccount);

        const topicToSubscribeContact = `whatsapp:empresa_id=${empresaSelecionada.id}&account_id=${singleAccount.id}&user_id=${colaboradorSelecionadoId}`;
        subscribeToTopicContact(topicToSubscribeContact);
      } else if (accounts.length > 1) {
        const sortedAccounts = [...accounts].sort((a, b) => {
          if (a.api_meta && !b.api_meta) {
            return -1;
          } else if (!a.api_meta && b.api_meta) {
            return 1;
          } else {
            return 0;
          }
        });
        setSelectedAccount(sortedAccounts[0]);
      }
    };

    const handleWebSocketClose = () => {
      webSocketInitialized = false;
      setIsConnected(false);
      console.log('WebSocket connection closed');
    };

    webSocketClient.connect(process.env.REACT_APP_API_WSS_URL);
    webSocketClient.ws.on('open', handleWebSocketOpen);
    webSocketClient.ws.on('close', handleWebSocketClose);
    webSocketClient.ws.on('error', () => setConnectionHasError(true));

    return () => {
      isMount = false;
      webSocketClient.disconnect();
    };
  }, [empresaSelecionada, signed, accounts]);

  useEffect(() => {
    handleLoadAccounts();
    buscarColaboradores();
  }, []);

  useEffect(() => {
    if (hasPermission && colaboradores.length > 0) {
      const userIsListed = colaboradores.some(
        (colaborador) => colaborador.id === user?.id
      );
      if (userIsListed) {
        //@ts-ignore
        setUsuarioSelecionado(user);
      } else {
        setUsuarioSelecionado(colaboradores[0]);
      }
    } else {
      //@ts-ignore
      setUsuarioSelecionado(user);
    }
  }, [colaboradores, webSocketInitialized]);

  const subscribeToTopicContact = (topicToSubscribe: string) => {
    const webSocketClient = webSocketClientRef.current;
    webSocketClient?.subscribe(topicToSubscribe, handleWebSocketNewContact);
  };
  const subscribeToTopicMessage = (topicToSubscribe: string) => {
    const webSocketClient = webSocketClientRef.current;
    webSocketClient?.subscribe(topicToSubscribe, handleWebSocketNewChatMessage);
  };

  function showNotification(msg: IMessage) {
    if ('Notification' in window) {
      // Verifica se as permissões de notificação foram concedidas pelo usuário
      if (Notification.permission === 'granted') {
        const ctt = contacts.find(
          (e) => e.account_id == msg.account_id && e.wa_id == msg.wa_id
        );
        const title = `${ctt?.name} te enviou uma nova mensagem`;
        const options = {
          body: msg.text,
          icon: `../assets/icon.png`,
        };

        new Notification(title, options);
      }
    }
  }

  useEffect(() => {
    if (selectedAccount) {
      const topicToSubscribeContact = `whatsapp:empresa_id=${empresaSelecionada?.id}&account_id=${selectedAccount?.id}&user_id=${colaboradorSelecionadoId}`;
      subscribeToTopicContact(topicToSubscribeContact);
      atualizarContatos();
      setSelectedContact(null);
      setOportunidadeSelecionada(null);
    }
  }, [selectedAccount]);

  async function handleLoadMessages() {
    if (!selectedContact?.wa_id) return;
    await api.get(
      `/meta/accounts/${selectedContact.account_id}/contacts/${selectedContact.wa_id}/messages/${colaboradorSelecionadoId}`
    );
  }

  /**
   * O parâmetro 'update' é usado para dizer ao websocket no backend
   * se ele deve retornar uma mensagem pro frontend ou não. Isso evita
   * a situação em que o contato selecionado seja o mesmo que receberá
   * uma nova mensagem causando um loop infinito na função de efeito
   * que monitora mudanças na lista de contatos.
   */
  async function updateMensagensLidas({
    conversaId,
    update = false,
  }: {
    conversaId: number;
    update?: boolean;
  }) {
    await api.post(`/meta/contato/conversas/${conversaId}/reset`, {
      update: update,
    });
  }

  async function handleLoadAccounts() {
    const { data } = await api.get(`/meta/accounts/`);
    setAccounts(data);
  }

  function selecionarOportunidade(oportunidade: Oportunidade | null) {
    setOportunidadeSelecionada(oportunidade);
  }

  useEffect(() => {
    if (!selectedContact?.wa_id || !empresaSelecionada?.id) return;

    const topicToSubscribe = `whatsapp:empresa_id=${empresaSelecionada.id}&account_id=${selectedContact.account_id}&wa_id=${selectedContact.wa_id}&user_id=${colaboradorSelecionadoId}`;
    subscribeToTopicMessage(topicToSubscribe);

    const conversa = selectedContact.conversas[0];
    handleLoadMessages();
    updateMensagensLidas({ conversaId: conversa.id, update: true });
    selectedContactRef.current = selectedContact;
  }, [selectedContact]);

  async function buscarColaboradores() {
    const url = `/common/empresas/${empresaSelecionada?.id}/comercial`;
    const { data } = await api.get<User[]>(url);
    if (data) setColaboradores(data);
  }

  function selecionarColaborador(userId: number) {
    setColaboradorSelecionadoId(userId);
  }

  const handleWebSocketNewChatMessage = (body: IMessagePayload) => {
    try {
      switch (body.TYPE) {
        case typeEnum.SINGLE:
          updateMessages([body.SINGLE], body.TYPE);
          break;

        case typeEnum.HYDRATATION:
          updateMessages(body.HYDRATATION, body.TYPE);
          break;

        default:
          break;
      }
    } catch (error) {
      console.error('Error parsing WebSocket message:', error);
    }
  };

  const handleWebSocketNewContact = (body: IPayload) => {
    try {
      switch (body.TYPE) {
        case typeEnum.SINGLE: {
          const contatoRecebido = body.SINGLE;

          if (selectedContactRef.current?.wa_id === contatoRecebido.wa_id) {
            contatoRecebido.conversas[0].nao_lidas = 0;
            updateMensagensLidas({
              conversaId: contatoRecebido.conversas[0].id,
              update: false,
            });
          }

          setContacts((contatos) => {
            const updatedContacts = contatos.map((contato) => {
              if (
                contato.wa_id === contatoRecebido.wa_id &&
                contato.account_id === contatoRecebido.account_id
              ) {
                return contatoRecebido;
              }
              return contato;
            });

            const foundContact = contatos.some(
              (contato) =>
                contato.wa_id === contatoRecebido.wa_id &&
                contato.account_id === contatoRecebido.account_id
            );

            if (!foundContact) {
              updatedContacts.push(body.SINGLE);
            }

            return updatedContacts.sort(
              (a, b) =>
                new Date(b.dt_last_message).getTime() -
                new Date(a.dt_last_message).getTime()
            );
          });
          break;
        }

        case typeEnum.HYDRATATION: {
          if (body.serverTime) setServerTime(body.serverTime);
          updateContacts(
            body.HYDRATATION.sort(
              (a, b) =>
                new Date(b.dt_last_message).getTime() -
                new Date(a.dt_last_message).getTime()
            )
          );
          break;
        }

        case typeEnum.PARTIAL: {
          setContacts(oldContacts => {
            const updatedContacts = [...oldContacts, ...body.PARTIAL];

            return updatedContacts.sort(
              (a, b) =>
                new Date(b.dt_last_message).getTime() -
                new Date(a.dt_last_message).getTime()
            )

          })
          break;
        }

        default:
          break;
      }
    } catch (error) {
      console.error('Error parsing WebSocket message:', error);
    }
  };

  async function loadMoreContacts(payload: ContactsPayload) {
    const { account_id, empresa_id, user_id } = payload;
    const topic = `whatsapp:empresa_id=${empresa_id}&account_id=${account_id}&user_id=${user_id}`;
    webSocketClientRef.current?.loadContacts(topic, JSON.stringify(payload));
  }

  function removeDuplicates(data: any, key: any) {
    // @ts-ignore
    return [...new Map(data.map((x) => [key(x), x])).values()];
  }

  function atualizarContatos() {
    const topic = `whatsapp:empresa_id=${empresaSelecionada!.id}&account_id=${
      selectedAccount!.id
    }&user_id=${user!.id}`;
    sendMessage(
      topic,
      JSON.stringify({
        type: 'contatos',
        body: {
          empresa_id: empresaSelecionada?.id,
          account_id: selectedAccount?.id,
          user_id: colaboradorSelecionadoId,
        },
      })
    );
  }

  const updateMessages = (msgs: IMessage[], type: typeEnum) => {
    if (!msgs.length) return;
    const msg = msgs[0];
    if (type == typeEnum.SINGLE) {
      !msg.is_sent && showNotification(msg);
      addMessage(msg.wa_id, msg.account_id, msg);
    } else if (type == typeEnum.HYDRATATION) {
      const key = msg.wa_id.concat(msg.account_id);
      setMessages((prev) => {
        const groupedMessages = new Map<string, IMessage[]>(prev);
        groupedMessages.set(key, msgs);
        return groupedMessages;
      });
    }
  };

  const addMessage = (wa_id: string, account_id: string, message: IMessage) => {
    const key = wa_id.concat(account_id);
    setMessages((prevMessages) => {
      const newMessages = new Map(prevMessages);
      if (newMessages.has(key)) {
        newMessages.get(key)!.push(message);
      } else {
        newMessages.set(key, [message]);
      }
      return newMessages;
    });
  };

  const updateContacts = (newContacts: IContact[]) => {
    // if (!isMount) return;
    setContacts(newContacts);
  };

  const selectContact = (contact: IContact | null) => {
    setSelectedContact(contact);
  };

  const selectAccount = (id: string) => {
    setContacts([]);
    const foundAccount = accounts.find((acc) => acc.id === id);
    if (foundAccount) setSelectedAccount(foundAccount);
  };

  // async function getMessageTemplates() {
  //   try {
  //     const { data } = await api.get(
  //       `/meta/webhooks/whatsapp/${selectedContact!.account_id}/templates`
  //     );

  //     setTemplates(data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  function sendTemplateMessage(
    mensagemTemplate: {
      name: string;
      components: Array<{ type: string; text?: string }>;
    },
    destinatario: IContact
  ) {
    if (
      !templates ||
      connectionHasError ||
      !empresaSelecionada?.id ||
      !destinatario?.account_id ||
      !destinatario?.wa_id
    ) {
      return;
    }

    const newTopic = `whatsapp:empresa_id=${empresaSelecionada.id}&account_id=${destinatario.account_id}&wa_id=${destinatario.wa_id}&user_id=${colaboradorSelecionadoId}`;
    subscribeToTopicMessage(newTopic);

    const { name: templateName, components } = mensagemTemplate;
    const templateObject = {
      name: templateName,
      language: {
        code: 'pt_BR',
      },
    };

    const templateBody = components.find(
      (componente) => componente.type === 'BODY'
    );

    if (!templateBody || !templateBody.text) {
      console.error('Template não encontrado');
      return;
    }

    const contato = destinatario ?? selectedContact;

    if (!contato) {
      console.error('Contato não encontrado');
      return;
    }

    const { wa_id, account_id, conversas, cliente_id, name } = contato;

    const message = JSON.stringify({
      type: 'template',
      body: {
        type: 'text',
        text: templateBody.text,
        nome: name,
        cliente_id: cliente_id,
        timestamps: new Date(),
        is_sent: true,
        wa_id: wa_id,
        account_id: account_id,
        template: templateObject,
        status: 'Q',
        oportunidade_id:
          conversas?.length > 0 ? conversas[0].oportunidade_id : null,
      },
    });

    sendMessage(newTopic, message);
  }

  const sendMessage = (topic: string, message: any, event = 'message') => {
    webSocketClientRef.current?.sendMessage(topic, message, event);
  };

  const contatoTemOportunidade = useMemo(() => {
    if (selectedContact) {
      const { conversas } = selectedContact;
      if (conversas && conversas.length > 0) {
        const hasOp = conversas.filter(
          (conversa) => conversa.oportunidade_id !== null
        );
        return hasOp.length > 0;
      }
    }
  }, [selectedContact]);

  return (
    <WhatsappContext.Provider
      value={{
        selectAccount,
        accounts,
        usuarioSelecionado,
        atualizarContatos,
        connectionHasError: !isConnected,
        contatoTemOportunidade,
        selectedAccount,
        colaboradores,
        selecionarColaborador,
        messages,
        contacts,
        selectedContact,
        selectContact,
        sendMessage,
        updateContacts,
        selecionarOportunidade,
        oportunidadeSelecionada,
        serverTime,
        colaboradorSelecionadoId,
        sendTemplateMessage,
        loadMoreContacts,
      }}
    >
      {children}
    </WhatsappContext.Provider>
  );
};

export const useWhatsappContext = () => useContext(WhatsappContext);
