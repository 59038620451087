import React, { useEffect, useState } from 'react';
import { TabContent, TabPane, Nav, Button } from 'reactstrap';
import AccountsListGroup from './AccountsListGroup';
import ContaSocialCard from './components/ContaSocialCard';
import FacebookAds from '../FacebookAds';
import api from '../../../../../../services/api';
import './components/styles.css';

export default function ContasSociais() {
  const [activeTab, setActiveTab] = useState('hidden');
  const [fbStatus, setFbStatus] = useState<any>(false);
  const [accounts, setAccounts] = useState<any>([]);
  const [connectedPages, setConnectedPages] = useState([]);

  async function carregarEquipesEmAccounts() {
    const { data } = await api.get('/meta/all-accounts');
    setAccounts(data);
  }
  const toggle = () => {
    setActiveTab('hidden');
  };

  useEffect(() => {
    carregarEquipesEmAccounts();
    checkFacebookStatus();
  }, []);

  async function checkFacebookStatus() {
    const response = await api.get('common/canais/facebook');
    const { is_valid, pages } = response.data;
    setFbStatus(is_valid);
    setConnectedPages(pages);
  }

  return (
    <>
      {activeTab === 'hidden' && (
        <Nav role="tablist" style={{ gap: '1rem' }}>
          <ContaSocialCard
            isConnected={accounts.length > 0}
            accounts={accounts}
            icon="fab fa-whatsapp"
            name="whataspp-ba"
            setTabActive={() => setActiveTab('whatsapp-ba')}
            title="WhatsApp"
          />
          <ContaSocialCard
            isConnected={fbStatus}
            icon="fab fa-facebook"
            name="facebookads"
            setTabActive={() => setActiveTab('facebookads')}
            title="Facebook Ads"
          />
        </Nav>
      )}
      <TabContent activeTab={activeTab}>
        <TabPane tabId="whatsapp-ba">
          <AccountsListGroup
            hideTab={toggle}
            accounts={accounts}
            onSuccess={carregarEquipesEmAccounts}
          />
        </TabPane>

        <TabPane tabId="facebookads">
          <Button onClick={toggle}>Voltar</Button>
          <FacebookAds fbStatus={fbStatus} connectedPages={connectedPages} />
        </TabPane>
      </TabContent>
    </>
  );
}
