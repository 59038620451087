import React, { useEffect, useState, useRef, useContext } from 'react';
import EmpresaContext from '../../../../contexts/Empresa';
import api from '../../../../services/api';
import moment from 'moment';

import Select2 from 'react-select2-wrapper';
import ReactQuill from 'react-quill';
import ReactDatetime from 'react-datetime';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import Links from '../anexos/Links';
import PonderarButton from '../PonderarButton';
import NotificationAlert from 'react-notification-alert';
import Header from './Header';
import {
  // UncontrolledTooltip,
  CardFooter,
  Button,
  FormGroup,
  Spinner,
  CardBody,
  // Container,
  Row,
  Col,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  PopoverBody,
  UncontrolledPopover,
  Badge,
  Modal,
  ModalHeader,
} from 'reactstrap';
import { verificarTamanhoConteudo } from '../../../../utils';
import './style.css'

let runAfterSave = null;

export default ({
  publicacaoId,
  anuncio,
  history,
  clone,
  onSuccess,
  onBack,
  onBackAnuncio,
  anuncioToBack,
  isOpen,
  toggle,
  anuncio_vigente, // criado para controle da pagina de campanhas
  reloadPublicacoes,
  ...props
}) => {
  const { empresaSelecionada, empresas, changeEmpresaSelecionada } = useContext(EmpresaContext);
  const [publicacao, setPublicacao] = useState({});
  const [destinoId, setDestinoId] = useState([]);
  const [titulo, setTitulo] = useState(null);
  const [corpoTexto, setCorpoTexto] = useState(null);
  const [showCabecalhoPadrao, setShowCabecalhoPadrao] = useState(true);
  const [showRodapePadrao, setShowRodapePadrao] = useState(true);
  const [textoImagem, setTextoImagem] = useState(null);
  const [dataPostagem, setDataPostagem] = useState(null);
  const [oldDataPostagem, setOldDataPostagem] = useState(null);
  const [formatoId, setFormatoId] = useState(null);
  const [trafegoId, setTrafegoId] = useState(null);
  const [sugestao, setSugestao] = useState(null);
  const [links, setLinks] = useState([]);
  const [trafegos, setTrafegos] = useState([]);
  const [formatos, setFormatos] = useState([]);
  const [destinos, setDestinos] = useState([]);
  const [alert, setAlert] = useState(null);
  const [erros, setErros] = useState({});
  const [saving, setSaving] = useState(false);
  const [fluxo, setFluxo] = useState({});
  const notificationAlert = useRef();
  const [anuncio_id, setAnuncioid] = useState(null);

  useEffect(() => {
    // console.log(anuncio_vigente);
    if (anuncio_vigente !== null) {
      setAnuncioid(anuncio_vigente.id);
    }
  }, [anuncio_vigente]);

  useEffect(() => {
    if (empresaSelecionada && empresaSelecionada?.id) {
      if (formatos.length === 0) loadFormatos();
      if (trafegos.length === 0) loadTrafegos();
      if (destinos.length === 0) loadDestinos();
      if (!fluxo.id) loadFluxo();
    }
  }, [empresaSelecionada]);

  useEffect(() => {
    if (publicacao.id) {
      if (empresaSelecionada?.id !== publicacao.empresa_id) {
        fixEmpresaSelecionada();
      }
      fill(publicacao);
      setOldDataPostagem(publicacao.data_postagem);
      runAfterSave = null;
    }
  }, [publicacao]);

  useEffect(() => {
    if (anuncio?.id) {
      fillByAnuncio();
    }
  }, [anuncio]);

  useEffect(() => {
    if (clone?.id) {
      setPublicacao({
        titulo: `Clone - ${clone.titulo}`,
      });
      clone.designer_responsavel_id = undefined;
      fill(clone);
    }
  }, [clone]);

  function fixEmpresaSelecionada() {
    const empresa = empresas.find((item) => item.id === publicacao.empresa_id);
    if (empresa) {
      changeEmpresaSelecionada(empresa);
      window.location.reload();
    } else {
      history.push('/publicacoes');
    }
  }

  async function loadFluxo() {
    try {
      const response = await api.get('/common/fluxos/vigente');
      setFluxo(await response.data);
    } catch (err) {
      notify('danger', 'Não foi possível carregar fluxo de fases.');
    }
  }

  //Carregue os campos com base no anuncio
  function fillByAnuncio() {
    fill({
      titulo: anuncio.nome,
      data_postagem: anuncio.dt_inicio,
    });
    setPublicacao({ titulo: anuncio.nome });
    loadTrafegoAnuncio();
  }

  //Preencher os campos do formulario
  function fill(publicacao) {
    setTitulo(publicacao.titulo);
    setDestinoId(publicacao?.destinos_id ? publicacao?.destinos_id : []);
    setCorpoTexto(publicacao.corpo_texto);
    setTextoImagem(publicacao.texto_imagem);
    setDataPostagem(publicacao.data_postagem ? new Date(publicacao.data_postagem) : null);
    setFormatoId(publicacao.formato_id);
    setTrafegoId(publicacao.trafego_id);
    setSugestao(publicacao.sugestao);
    setShowCabecalhoPadrao(publicacao.show_cabecalho_padrao ? true : false);
    setShowRodapePadrao(publicacao.show_rodape_padrao ? true : false);
  }

  async function loadTrafegoAnuncio() {
    const valor = await findTrafego('trafego_anuncio');
    if (valor) setTrafegoId(parseInt(valor));
  }

  async function findTrafego(key) {
    try {
      const response = await api.get(`common/parametros/${key}`);
      const valor = await response.data.valor;
      return valor;
    } catch (err) {
      console.error(err);
    }
  }

  async function loadDestinos() {
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada?.id}/destinos/`,
        {
          params: {
            situacao: 'A',
          },
        }
      );
      setDestinos(response.data);
    } catch (err) {
      notify('danger', 'Não foi possível carregar as destinos.');
    }
  }

  async function loadTrafegos() {
    try {
      const response = await api.get('/marketing/trafegos', {
        params: { anuncio: anuncio != null },
      });
      const array = await response.data;
      setTrafegos(array);
    } catch (err) {
      console.error(err);
      notify('danger', 'Não foi possível carregar trafegos.');
    }
  }

  async function loadFormatos() {
    try {
      const response = await api.get('/marketing/formatos');
      setFormatos(response.data);
    } catch (err) {
      console.error(err);
      notify('danger', 'Não foi possível carregar formatos.');
    }
  }

  async function afterSave(id) {
    if (runAfterSave) await runAfterSave(id);
    successAlert('Publicação salva');
  }

  // save direto
  async function handleSave() {
    if (
      verificarTamanhoConteudo(corpoTexto) ||
      verificarTamanhoConteudo(textoImagem) ||
      verificarTamanhoConteudo(sugestao)
    ) {
      notify(
        'danger',
        'Por favor, reduza o comprimento do conteúdo nos editores de texto.'
      );
      return;
    }
    setSaving(true);
    runAfterSave = null;
    await insert();
  }

  async function insert() {
    try {
      const response = await api.post(
        `common/empresas/${empresaSelecionada?.id}/publicacoes/simplificada`, // rota alterada
        bind()
      );
      const { id } = await response.data;
      if (response.status === 201) {
        await afterSave(id);
      }
    } catch (error) {
      setSaving(false);
      console.log(error);
      notify('danger', 'Não foi possível salvar publicação');
      updateErros(error);
    }
  }

  function updateErros(error) {
    try {
      setErros({});
      if (error) {
        const errors = {};
        for (let e of error.response.data) {
          errors[e.field] = e.message;
        }
        setSaving(false);
        setErros(errors);
      }
    } catch (error) {
      setSaving(false);
      console.error(error);
    }
  }

  function bind() {
    return {
      titulo,
      fase_atual_id: 5,
      data_postagem:
        moment(dataPostagem).format('YYYY-MM-DD HH:mm:ss') ===
        moment(oldDataPostagem).format('YYYY-MM-DD HH:mm:ss')
          ? undefined
          : dataPostagem,
      formato_id: formatoId,
      trafego_id: trafegoId,
      corpo_texto: corpoTexto,
      links,
      anuncio_id,
    };
  }

  const notify = (type, title, msg = '') => {
    let options = {
      place: 'tc',
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: 'ni ni-bell-55',
      autoDismiss: 3,
    };
    if (notificationAlert.current)
      notificationAlert.current.notificationAlert(options);
  };

  const redirectBack = () => {
    if (onBack) {
      onBack();
    } else {
      if (onBackAnuncio) {
        reloadPublicacoes();
        setAlert(null);
        toggle();
      } else {
        reloadPublicacoes();
        setAlert(null);
        toggle();
      }
    }
    setTimeout(() => {
      setDataPostagem(null);
      setCorpoTexto(null);
      setFormatoId(null);
      setTrafegoId(null);
    }, 2100);
  };

  const hasFeedSelected = () => {
    const ids = destinos
      .filter((item) => item.tipo == 'feed')
      .map((item) => item.id);
    let has = 0;
    ids.forEach((item) => {
      if (destinoId.includes(item)) has = has + 1; //Incrementa o has caso ache um item
    });
    return has > 0;
  };

  const successAlert = (msg) => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: 'block', marginTop: '-100px', maxWidth: '500px' }}
        title={msg}
        onConfirm={() => (onSuccess ? onSuccess() : redirectBack())}
        confirmBtnBsStyle="success"
        showConfirm={false}
        btnSize=""
      />
    );
    setSaving(false);
    setTimeout(onSuccess ? onSuccess : redirectBack, 2000);
  };

  return (
    <>
      {alert}
      <Modal isOpen={isOpen} toggle={toggle} backdropClassName="pub-modal" style={{background: "white", borderRadius: "0.4375rem"}}>
      <ModalHeader toggle={toggle}>Publicação Simplificada</ModalHeader>
      <div className="rna-wrapper pubSimpliNotifi">
        <NotificationAlert ref={notificationAlert} />
      </div>
      {/* <Row> */}
        {/* <Col> */}
          <div className={anuncio ? '' : 'card'} style={{marginBottom: 0}}>
            <Header
              style={anuncio ? { paddingTop: 0 } : null}
              publicacao={publicacao}
              onTituloChanged={setTitulo}
              erros={erros}
              alert={setAlert}
              notify={notify}
              onPublicacaoChanged={setPublicacao}
            />
            <CardBody style={{ 
                backgroundColor: '#ffff',
                boxShadow: "none",
              }}>
              {/* <Row style={{flexDirection: "column"}}> */}
              <div>
                <Col>
                  <FormGroup>
                    <label className="form-control-label">
                      Data da publicação*
                    </label>
                    <InputGroup className="input-group">
                      <ReactDatetime
                        value={dataPostagem}
                        inputProps={{
                          placeholder: 'Data para publicação...',
                          style: { position: 'absolute' },
                        }}
                        isValidDate={(current) => {
                          return current.isAfter(
                            new Date(
                              new Date().setDate(new Date().getDate() - 1)
                            )
                          );
                        }}
                        dateFormat="DD/MM/YYYY"
                        timeFormat="HH:mm"
                        onChange={(e) => setDataPostagem(e)}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText
                          style={{
                            padding: '10px',
                            height: 'calc(2.75rem + 2px)',
                          }}
                        >
                          <i class="far fa-calendar-alt" />
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                    <small class="text-danger">
                      {erros.data_postagem || ''}
                    </small>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <label className="form-control-label">Formato*</label>
                    <Select2
                      className="form-control"
                      value={formatoId}
                      options={{
                        placeholder: 'Selecione um formato...',
                      }}
                      onSelect={(e) => setFormatoId(e.target.value)}
                      data={formatos.map((item) => ({
                        id: item.id,
                        text: item.nome,
                      }))}
                    />
                    <small class="text-danger">{erros.formato_id || ''}</small>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <label className="form-control-label">Tráfego*</label>
                    <Select2
                      className="form-control"
                      value={trafegoId}
                      options={{
                        placeholder: 'Selecione um formato...',
                      }}
                      onSelect={(e) => setTrafegoId(e.target.value)}
                      data={trafegos.map((item) => ({
                        id: item.id,
                        text: item.nome,
                      }))}
                    />
                    <small class="text-danger">{erros.trafego_id || ''}</small>
                  </FormGroup>
                </Col>
              </div>
              {/* </Row> */}
              <Row>
                <Col lg="12" sm="12" md="12">
                  <FormGroup>
                    <div className="d-flex justify-content-between">
                      <label className="form-control-label d-flex justify-content-between">
                        <div>
                          Corpo (legenda){hasFeedSelected() ? '*' : ''}
                          <Button
                            color="secondary"
                            id="tooltipLegenda"
                            outline
                            size="sm"
                            type="button"
                          >
                            ?
                          </Button>
                          <UncontrolledPopover
                            placement="top"
                            target="tooltipLegenda"
                          >
                            <PopoverBody>
                              Informe o texto que será apresentado no corpo da
                              publicação...
                            </PopoverBody>
                          </UncontrolledPopover>
                        </div>
                      </label>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {empresaSelecionada &&
                          empresaSelecionada.id &&
                          empresaSelecionada.cabecalho_publicacao && (
                            <div
                              className="custom-control custom-checkbox"
                              style={{ marginBottom: -5 }}
                            >
                              <input
                                className="custom-control-input"
                                id="check-cabecalho"
                                type="checkbox"
                                checked={showCabecalhoPadrao}
                                onChange={() =>
                                  setShowCabecalhoPadrao(!showCabecalhoPadrao)
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="check-cabecalho"
                              >
                                Inserir Cabeçalho Padrão
                              </label>
                            </div>
                          )}
                        <Badge
                          style={{ marginLeft: 10 }}
                          size="sm"
                          color="secondary"
                          title={`${
                            corpoTexto?.replace(/<[^>]*>/g, '').length || 0
                          } caracteres no corpo`}
                          className="badge-md badge-circle badge-floating border-white"
                        >
                          {corpoTexto?.replace(/<[^>]*>/g, '')?.length || 0}
                        </Badge>
                      </div>
                    </div>
                    <ReactQuill
                      value={corpoTexto || ''}
                      theme="snow"
                      modules={{
                        toolbar: [
                          ['bold', 'italic'],
                          ['link', 'blockquote', 'code'],
                          [
                            {
                              list: 'ordered',
                            },
                            {
                              list: 'bullet',
                            },
                          ],
                        ],
                      }}
                      onChange={(v) => setCorpoTexto(v)}
                    />
                    <div className="d-flex justify-content-between">
                      <small class="text-danger">
                        {erros.corpo_texto || ''}
                      </small>
                      {empresaSelecionada &&
                        empresaSelecionada.id &&
                        empresaSelecionada.rodape_publicacao && (
                          <div
                            className="custom-control custom-checkbox"
                            style={{ marginTop: 8, marginRight: 52 }}
                          >
                            <input
                              className="custom-control-input"
                              id="check-rodape"
                              type="checkbox"
                              checked={showRodapePadrao}
                              onChange={() =>
                                setShowRodapePadrao(!showRodapePadrao)
                              }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="check-rodape"
                            >
                              Inserir Rodapé Padrão
                            </label>
                          </div>
                        )}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <label />
                    <Links
                      publicacaoId={publicacao.id ? publicacao.id : null}
                      alert={setAlert}
                      notify={notify}
                      onChangeLinkList={setLinks}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter
              className=""
              style={{
                position: 'sticky',
                bottom: 0,
                // border: '2 solid',
                // boxShadow: '0px 0px 2px 2px #f5f5f5',
                zIndex: 50,
              }}
            >
              <Row>
                <PonderarButton
                  publicacao={publicacao}
                  alert={setAlert}
                  onError={(msg) => notify('danger', msg)}
                  onSuccess={successAlert}
                />
                <Col>
                  <div
                    className="float-right "
                    style={{
                      flexWrap: 'wrap',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      className="ml-auto mt-1"
                      color="link"
                      data-dismiss="modal"
                      type="button"
                      // onClick={() => redirectBack()}
                      onClick={() => {toggle()}}
                    >
                      Fechar
                    </Button>
                    <Button
                      id={`salvar-publicacao-${publicacao.id}`}
                      className=" mt-1"
                      disabled={saving}
                      color="primary"
                      onClick={handleSave}
                    >
                      <Spinner
                        hidden={!saving}
                        className="mr-2"
                        color="light"
                        size="sm"
                      />
                      Salvar
                    </Button>
                  </div>
                </Col>
              </Row>
            </CardFooter>
          </div>
        {/* </Col> */}
      {/* </Row> */}
      </Modal>
    </>
  );
};
