import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Row,
  Spinner,
  Tooltip,
} from 'reactstrap';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ChatBox, ChatHeader, MessageBubble } from './styles';
import { useWhatsappContext } from '../../../../../contexts/Whatsapp';
import EmpresaContext from '../../../../../contexts/Empresa';
import playneeIcon from '../../../../../assets/icon.png';
import api from '../../../../../services/api';
import AuthContext from '../../../../../contexts/Auth';
import AudioRecorder from '../AudioRecorder';
import { ContinuarConversaBtn } from '../ContinuarConversaBtn';
import ChatWindow from '../../../../../components/Conversas/ChatWindow';
import IMessage from '../../../../../entities/Meta/message';
import NotificationAlert from 'react-notification-alert';

interface MsgBoxProps {}

const MsgBox: React.FC<MsgBoxProps> = () => {
  const { user } = useContext(AuthContext);
  const { empresaSelecionada } = useContext(EmpresaContext);
  const {
    messages,
    selectedContact,
    sendMessage,
    connectionHasError,
    selectedAccount,
  } = useWhatsappContext();

  const [rows, setRows] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [msgToSend, setMsgToSend] = useState(``);
  const [templates, setTemplates] = useState<any>([]);
  const [templateSelecionada, setTemplateSelecionada] = useState<any>(null);
  const [fileToUpload, setFileToUpload] = useState<File | Blob | null>(null);
  const [isRecordingAudio, setIsRecordingAudio] = useState(false);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const boxMsgRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const notificationAlert = useRef<NotificationAlert>(null);

  const topicChat = `whatsapp:empresa_id=${empresaSelecionada?.id}&account_id=${
    selectedContact?.account_id
  }&wa_id=${selectedContact?.wa_id}&user_id=${user!.id}`;

  const contactKey = useMemo(() => {
    return selectedContact
      ? selectedContact.wa_id.concat(selectedContact.account_id)
      : null;
  }, [selectedContact]);

  let chatMessages: IMessage[] = useMemo(() => {
    if (contactKey) {
      const mappedMessages = messages.get(contactKey);
      return mappedMessages ? [...mappedMessages] : [];
    } else {
      return [];
    }
  }, [messages, selectedContact, contactKey]);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const isAPIMeta = Boolean(selectedAccount?.api_meta);

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  useEffect(() => {
    setFileToUpload(null);
  }, [selectedContact]);

  const handleTextAreaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRows(event.target.value.split('\n').length);
    setMsgToSend(event.currentTarget.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !event.shiftKey && !connectionHasError) {
      event.preventDefault();
      handleSend('text');
    }
  };

  async function handleSaveAudio(audioFile: File | Blob) {
    if (isAPIMeta) {
      const { mediaId, type } = await handleMediaUpload(audioFile);
      if (mediaId && type) {
        sendMessage(
          topicChat,
          JSON.stringify({
            type: 'media',
            body: {
              type: 'audio',
              text: '',
              timestamps: new Date(),
              is_sent: true,
              wa_id: selectedContact?.wa_id,
              account_id: selectedContact?.account_id,
              media_id: mediaId,
              status: selectedContact?.classificacao,
            },
          })
        );
      }
    } else {
      if (!selectedAccount || !selectedContact) return;

      const { session_id } = selectedAccount;
      let formData = new FormData();
      formData.append('arquivo', audioFile);
      formData.append('session_id', session_id);

      await api.post(
        `/meta/conversas/${selectedContact.conversas[0].id}/wpp-media`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data;',
          },
        }
      );
    }
  }

  async function getMessageTemplates() {
    try {
      setIsLoading(true);
      const { data } = await api.get(
        `/meta/webhooks/whatsapp/${selectedContact!.account_id}/templates`
      );

      setTemplates(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleSendTemplateMessage() {
    if (!templates || connectionHasError) return;

    const templateObject = {
      name: templateSelecionada.name,
      language: {
        code: 'pt_BR',
      },
    };

    const templateBody = templateSelecionada.components.find(
      (componente: any) => componente.type === 'BODY'
    );

    sendMessage(
      topicChat,
      JSON.stringify({
        type: 'template',
        body: {
          type: 'text',
          // TODO: templates com parâmetros
          // substituir os valores e enviar o texto correto
          // usando a funçao substituirParametros
          text: templateBody.text,
          timestamps: new Date(),
          is_sent: true,
          wa_id: selectedContact?.wa_id,
          account_id: selectedContact?.account_id,
          template: templateObject,
          status: selectedContact?.classificacao,
          oportunidade_id:
            selectedContact?.conversas[0].oportunidade_id ?? null,
        },
      })
    );

    setModal(false);
  }

  async function handleSendAPIMediaMessage() {
    if (!fileToUpload || !selectedAccount || selectedAccount.api_meta) return;

    const { session_id } = selectedAccount;
    let formData = new FormData();
    formData.append('arquivo', fileToUpload);
    formData.append('session_id', session_id);

    await api.post(
      `/meta/conversas/${selectedContact?.conversas[0].id}/wpp-media`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data;',
        },
      }
    );

    setFileToUpload(null);

    return;
  }

  const handleMedia = useCallback(handleSendAPIMediaMessage, [fileToUpload]);

  async function handleMediaUpload(mediaFile: File | Blob) {
    let formData = new FormData();
    formData.append('arquivo', mediaFile);
    // pnid é o phone-number-id (account_id)
    formData.append('pnid', selectedContact!.account_id);

    const {
      data: { mediaId, type, subtype },
    } = await api.post(`/meta/webhooks/whatsapp/media`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data;',
      },
    });

    setFileToUpload(null);

    return { mediaId, type, subtype };
  }

  const handleSend = async (messageType: 'text' | 'media') => {
    if (connectionHasError) return;
    if (messageType === 'media' && fileToUpload) {
      const { mediaId, type } = await handleMediaUpload(fileToUpload);

      if (mediaId && type) {
        sendMessage(
          topicChat,
          JSON.stringify({
            type: 'media',
            body: {
              type,
              text: '',
              timestamps: new Date(),
              is_sent: true,
              wa_id: selectedContact?.wa_id,
              account_id: selectedContact?.account_id,
              media_id: mediaId,
              status: selectedContact?.classificacao,
            },
          })
        );
        clearFileInput();
      }
      return;
    }

    sendMessage(
      topicChat,
      JSON.stringify({
        type: 'message',
        body: {
          type: 'text',
          text: msgToSend,
          timestamps: new Date(),
          is_sent: true,
          wa_id: selectedContact?.wa_id,
          account_id: selectedContact?.account_id,
          status: selectedContact?.classificacao,
        },
      })
    );

    setRows(1);
    setMsgToSend(``);
  };

  function notify(type: string, msg: string) {
    let options = {
      place: 'tc',
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: 'ni ni-bell-55',
      autoDismiss: 3,
    };
    notificationAlert?.current?.notificationAlert(options);
  }

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      const mediaToUpload = event.target.files[0];
      if (mediaToUpload.size > 5 * 1024 * 1024) {
        notify('danger', 'O arquivo deve ser menor que 5 MB.');
        clearFileInput();
      } else {
        setFileToUpload(mediaToUpload);
      }
    }
  };

  function clearFileInput() {
    setFileToUpload(null);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  }

  const formatPhoneNumber = (phoneNumber: string) => {
    const numero = phoneNumber.slice(2);
    if (numero.length <= 2) {
      return numero;
    } else if (numero.length <= 7) {
      return `${numero.slice(0, 2)} ${numero.slice(2)}`;
    } else {
      return `${numero.slice(0, 2)} ${numero.slice(2, 6)}-${numero.slice(
        6,
        11
      )}`;
    }
  };

  const mensagemLinkWeb = useMemo(() => {
    return `Olá ${selectedContact?.name}! Iniciamos nosso atendimento no número oficial da ${empresaSelecionada?.nome} - ${selectedAccount?.phone_number}. Vamos continuar por aqui?`;
  }, [empresaSelecionada, selectedAccount, selectedContact]);

  if (!selectedContact) {
    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: '100%', opacity: '0.25' }}
      >
        <img src={playneeIcon} alt="Playnee" />
      </div>
    );
  }

  return (
    <>
      <div className="rna-wrapper" style={{ zIndex: 1060 }}>
        <NotificationAlert ref={notificationAlert} />
      </div>

      {isAPIMeta && (
        <Modal size="lg" isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Mensagens Padrão</ModalHeader>
          <ModalBody>
            {isLoading && <Spinner size="sm" />}
            {templates.length === 0 && !isLoading && (
              <p>Nenhuma mensagem padrão encontrada.</p>
            )}
            {!isLoading &&
              templates?.map((template: any) => (
                <Card
                  key={template.name}
                  onClick={() => {
                    setTemplateSelecionada(template);
                  }}
                  style={{
                    cursor: 'pointer',
                    border:
                      template?.name === templateSelecionada?.name
                        ? '2px solid blue'
                        : '2px solid transparent',
                  }}
                >
                  <CardBody>
                    <CardTitle>{template.name}</CardTitle>
                    <CardText>
                      <MessageBubble $isSent={true}>
                        <div
                          className="box--message"
                          style={{ position: 'relative' }}
                        >
                          <p className="message--text">
                            {template.components[0]?.text}
                          </p>
                        </div>
                        <div className="box--timestamp">
                          <small className="message--timestamp">
                            {new Date().toLocaleTimeString()}
                          </small>
                        </div>
                      </MessageBubble>
                    </CardText>
                  </CardBody>
                </Card>
              ))}
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={handleSendTemplateMessage}
              disabled={!templateSelecionada}
            >
              Enviar
            </Button>{' '}
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}

      <Row>
        <ChatHeader className="col pr-0">
          <div
            className="d-flex w-100 align-items-center"
            style={{ gap: '8px' }}
          >
            <span style={{ fontSize: '2rem', color: '#ccd0d8' }}>
              <i className="fas fa-user-circle"></i>
            </span>
            <div className="d-flex flex-column ml-1">
              <span style={{ display: 'inline-block' }}>
                {selectedContact?.name}
              </span>
              <small className="text-muted">
                {formatPhoneNumber(selectedContact?.wa_id)}
              </small>
            </div>
            <ContinuarConversaBtn
              waId={selectedContact?.wa_id}
              text={mensagemLinkWeb}
            />
          </div>
        </ChatHeader>
      </Row>
      <div
        className="row"
        style={{ overflowY: 'scroll', flex: 1 }}
        ref={boxMsgRef}
      >
        <>
          {fileToUpload && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
                flexDirection: 'column',
                background: '#f3f3f3',
              }}
            >
              <span
                className="text-muted"
                style={{
                  fontSize: '1rem',
                  borderRadius: '100px',
                  alignSelf: 'start',
                  padding: '.5rem',
                  marginLeft: '2rem',
                  cursor: 'pointer',
                }}
                onClick={() => clearFileInput()}
              >
                <i className="fas fa-times" />
              </span>
              {fileToUpload.type.includes('application') && (
                <span style={{ fontSize: '2rem' }} className="text-muted">
                  <i className="fas fa-file"></i>
                </span>
              )}
              {fileToUpload.type.includes('image') && (
                <img
                  src={URL.createObjectURL(fileToUpload) || ''}
                  style={{ maxWidth: '400px', borderRadius: '4px' }}
                />
              )}
              {fileToUpload.type.includes('video') && (
                <video
                  controls
                  src={URL.createObjectURL(fileToUpload) || ''}
                  style={{ maxWidth: '325px' }}
                />
              )}
              {isAPIMeta ? (
                <Button
                  color="primary"
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    borderRadius: '8px',
                    fontSize: '2rem',
                    marginTop: '8px',
                  }}
                  onClick={() => handleSend('media')}
                >
                  <i className="fas fa-check-circle" />
                </Button>
              ) : (
                <Button
                  color="primary"
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    borderRadius: '8px',
                    fontSize: '2rem',
                    marginTop: '8px',
                  }}
                  onClick={() => handleMedia()}
                >
                  <i className="fas fa-check-circle" />
                </Button>
              )}
            </div>
          )}
          {!fileToUpload && <ChatWindow messages={chatMessages} />}
        </>
      </div>
      {!fileToUpload && (
        <div
          style={{
            position: 'relative',
            height: '58px',
            borderTop: '1px solid rgb(236, 239, 243)',
          }}
        >
          <ChatBox
            className="text-box"
            disabled={!!fileToUpload || isRecordingAudio || connectionHasError}
            placeholder="Digite sua mensagem..."
            value={msgToSend}
            onChange={handleTextAreaChange}
            onKeyPress={handleKeyPress}
            rows={rows}
            wrap="hard"
            cols={40}
            type="textarea"
            isRecordingAudio={isRecordingAudio}
          />
          <div
            style={{
              position: 'absolute',
              top: '17px',
              width: '8%',
              display: 'flex',
              gap: '8px',
              justifyContent: 'center',
            }}
          >
            <input
              type="file"
              accept=".pdf,.jpeg,.mp4"
              onChange={handleFileChange}
              ref={inputRef}
              style={{ display: 'none' }}
            />
            <Button
              onClick={handleClick}
              color="primary"
              size="sm"
              style={{
                color: 'white',
                width: '100%',
                borderRadius: '100px',
              }}
            >
              <span className="text-white" style={{ fontSize: '1rem' }}>
                <i className="fas fa-paperclip"></i>
              </span>
            </Button>
            {isAPIMeta && (
              <>
                <Button
                  id="template"
                  onClick={() => {
                    toggle();
                    getMessageTemplates();
                  }}
                  color="primary"
                  size="sm"
                  style={{
                    color: 'white',
                    width: '100%',
                    borderRadius: '100px',
                  }}
                >
                  <span className="text-white" style={{ fontSize: '1rem' }}>
                    <i className="fas fa-clone"></i>
                  </span>
                </Button>
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpen}
                  target="template"
                  toggle={toggleTooltip}
                >
                  Mensagens pré-validadas
                </Tooltip>
              </>
            )}
          </div>
          {msgToSend.length > 0 && (
            <div
              style={{
                position: 'absolute',
                bottom: '8px',
                right: '16px',
              }}
            >
              <Button
                color="primary"
                size="sm"
                style={{
                  color: 'white',
                  width: '100%',
                  borderRadius: '100px',
                }}
                onClick={() => handleSend('text')}
              >
                <i className="ni ni-bold-right" />
              </Button>
            </div>
          )}
          {msgToSend.length === 0 && (
            <AudioRecorder
              isRecording={isRecordingAudio}
              setIsRecording={setIsRecordingAudio}
              handleSave={handleSaveAudio}
            />
          )}
        </div>
      )}
    </>
  );
};

export default MsgBox;
