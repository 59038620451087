import React, { useState, useEffect, useContext } from 'react';
import EmpresaContext from "../../../../../contexts/Empresa";
import api from "../../../../../services/api";

import Preview from '../../Preview';
import Historico from '../../Historico'
import ReactBSAlert from "react-bootstrap-sweetalert";
import './header.css'
import { NameContainer } from "./styles";
import {
    Button,
    Modal,
    CardHeader,
    Row,
    Col,
    Input,
    UncontrolledTooltip
} from "reactstrap";

export default ({
    publicacao = {},
    erros,
    onTituloChanged,
    onPublicacaoChanged,
    notify,
    alert,
    style }, ...props) => {
    const { empresaSelecionada } = useContext(EmpresaContext)
    const [publicacaoPreviewId, setPublicacaoPreviewId] = useState(null)
    const [titulo, setTitulo] = useState(null)
    const [situacao, setStuacao] = useState(null)
    const [situacoes, setSituacoes] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [titleWidth, setTitleWidth] = useState(100)

    useEffect(() => {
        if (titulo) {
            setTitleWidth((titulo.length + 1) * 13)
        } else {
            setTitleWidth(100)
        }
    }, [titulo])

    useEffect(() => {
        if (publicacao.id) {
            setStuacao(publicacao.situacao)
            setTitulo(publicacao.titulo)
        } else if (publicacao.titulo) {
            setTitulo(publicacao.titulo)
        }
    }, [publicacao])

    useEffect(() => {
        const p = publicacao
        p.situacao = situacao
        if (onPublicacaoChanged) onPublicacaoChanged(p)
    }, [situacao])

    useEffect(() => {
        onTituloChanged(titulo)
    }, [titulo])

    useEffect(() => {
        if (situacoes.length === 0) loadSituacoes()
    }, [])
    async function loadSituacoes() {
        try {
            const response = await api.get("/marketing/situacoes/publicacoes");
            setSituacoes(response.data)
        } catch (err) {
            notify("danger", "Não foi possível carregar situações.");
        }
    }

    async function changeSituacao(toSituacao) {
        try {
            await api.put(`/common/empresas/${empresaSelecionada?.id}/publicacoes/${publicacao.id}/situacoes/${toSituacao.id}`);
            setStuacao(toSituacao.id)
            alert(
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title={toSituacao.label}
                    showCancel={false}
                    onConfirm={() => alert(null)}
                    confirmBtnBsStyle='primary'
                    confirmBtnText="Ok"
                    btnSize=""
                >
                </ReactBSAlert>
            )
        } catch (err) {
            alert(null)
            notify('danger', 'Não foi possível alterar situação')
        }
    }

    function formartDate(dt) {
        var options = { year: "numeric", month: "long", day: "numeric" };
        const date = dt ? new Date(dt) : new Date()
        return date.toLocaleDateString("pt-br", options);
    }

    return (
        <>
            <Preview
                isOpen={publicacaoPreviewId}
                close={() => {
                    setPublicacaoPreviewId(null)
                }}
                publicacaoId={publicacaoPreviewId}
                readOnly={true}
                onChangePublicacao={() => { }}
            />
            <CardHeader
                style={{
                    position: "sticky",
                    top: 0,
                    zIndex: 100,
                    border: "1 solid",
                    boxShadow: "0px 2px #f5f5f5",
                    ...style,
                }}
            >
                <Modal
                    className="modal-dialog-centered"
                    isOpen={showModal}
                    toggle={() => setShowModal(!showModal)}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Histórico
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setShowModal(!showModal)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Historico
                            publicacaoId={publicacao.id}
                            notify={notify}
                        />
                    </div>
                </Modal>
                <Row>
                    <Col lg="8" sm="12" md="8">
                        <NameContainer>
                            <Input
                                className="form-control-flush"
                                placeholder="Título*..."
                                type="text"
                                name="titulo"
                                value={titulo}
                                onChange={e => setTitulo(e.target.value)}
                                style={{
                                    fontSize: 22,
                                    color: "black",
                                    width: titleWidth
                                }}
                            />
                            {
                                publicacao?.__meta__?.aprovacao && <>
                                    <UncontrolledTooltip
                                        delay={0}
                                        placement="top"
                                        target={"btn_preview_" + publicacao.id}
                                    >
                                        Preview
                                    </UncontrolledTooltip>
                                    <Button
                                        id={"btn_preview_" + publicacao.id}
                                        className="btn-sm"
                                        color="secondary"
                                        onClick={() => {
                                            setPublicacaoPreviewId(publicacao.id)
                                        }}
                                        outline>
                                        <i class="far fa-eye"></i>
                                    </Button>
                                </>
                            }
                            <small class="text-danger">
                                {erros.titulo || ''}
                            </small>
                        </NameContainer>

                    </Col>
                    <Col lg="4" sm="12" md="4">
                        <small
                            className="text-muted float-right"
                        >
                            <span title="Data de cadastro">
                                {formartDate(publicacao.created_at || new Date())}
                            </span>
                            {
                                publicacao.id &&
                                <Button
                                    color="link"
                                    title="Histórico"
                                    onClick={() => setShowModal(!showModal)}
                                >
                                    <i class="far fa-clock"></i>
                                </Button>
                            }
                        </small>
                        <br />
                    </Col>
                </Row>
            </CardHeader >
        </>
    );
}
