import React, { useState, useEffect, useContext, useRef } from 'react';
import EmpresaContext from '../../../contexts/Empresa';
import moment from 'moment';
import { usePersistedState } from '../../../hooks';
import api from '../../../services/api';

import Filters from '../../../components/Headers/Filters';
import ReactDatetime from 'react-datetime';
import Select2 from 'react-select2-wrapper';
import Select from 'react-select';
import {
  CardBody,
  Row,
  Col,
  Input,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from 'reactstrap';

const firstDayOfCadastro = () => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth());
};

export default ({ title, load, history, notify, ...props }) => {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const [dataCadastroInicial, setDataCadastroInicial] = usePersistedState(
    'dataCadastroInicial',
    firstDayOfCadastro()
  );
  const [dataCadastroFinal, setDataCadastroFinal] = usePersistedState(
    'dataCadastroFinal',
    moment()
  );
  const [equipes, setEquipes] = usePersistedState('equipes', []);
  const [campanha, setCampanha] = usePersistedState('campanha', null);
  const [anuncio, setAnuncio] = usePersistedState('anuncio', null);
  const [funis, setFunis] = usePersistedState('funis', []);
  const [estagios, setEstagios] = usePersistedState('estagios', []);

  const [campanhas, setCampanhas] = useState([]);
  const [anuncios, setAnuncios] = useState([]);
  const [equipesList, setEquipesList] = useState([]);
  const [funisList, setFunisList] = useState([]);
  const [estagiosList, setEstagiosList] = useState([]);

  const [monitorClearFilters, setMonitorClearFilters] = useState(undefined);

  const equipesRef = useRef(null);

  useEffect(() => {
    if (monitorClearFilters) {
      search();
    }
  }, [monitorClearFilters]);

  useEffect(() => {
    const firstLoad = async () => {
      if (equipesList.length === 0) await loadEquipes();
      if (campanhas.length === 0) await loadCampanhas();
      if (funisList.length === 0) await loadFunis();
      if (estagiosList.length === 0) await loadEstagios();
    };
    firstLoad();
  }, []);

  useEffect(() => {
    if (equipesList && equipesList.length > 0) {
      fillEquipes();
    }
  }, [equipesList]);

  useEffect(() => {
    if (
      equipesList &&
      equipesList.length > 0 &&
      campanhas &&
      campanhas.length > 0
    ) {
      search();
    }
  }, [campanhas, equipesList]);

  useEffect(() => {
    if (equipes.length === 0) fillEquipes();
  }, [equipes]);

  useEffect(() => {
    loadEstagios();
  }, [funis]);

  function fillEquipes() {
    if (equipesList && equipesList.length > 0) {
      setEquipes(equipesList.map((item) => item.id));
    }
  }

  async function search() {
    await load({
      dataCadastroInicial,
      dataCadastroFinal,
      equipes,
      campanha,
      anuncios: anuncio && anuncio > 0 ? [anuncio] : [],
      funis,
      estagios,
    });
  }

  async function handleClearFilter() {
    setDataCadastroInicial(firstDayOfCadastro());
    setDataCadastroFinal(new Date());
    setEquipes([]);
    setCampanha(null);
    setAnuncio(null);
    setFunis([]);
    setEstagios([]);
  }

  async function loadAnuncios(campanha_id) {
    setCampanha(campanha_id);
    setAnuncio(null);
    if (campanha_id) {
      const response = await api.get(
        `marketing/anuncios/${campanha_id}/${empresaSelecionada.id}`
      );
      const data = await response.data;
      if (data.length > 0) data.unshift({ id: -1, nome: '- Todos -' });
      setAnuncios(Array.from(data));
    }
  }

  async function loadCampanhas() {
    const response = await api.get(
      `marketing/campanha/${empresaSelecionada.id}`
    );
    const data = await response.data;
    setCampanhas(data);
  }

  async function loadEquipes() {
    try {
      const response = await api.get(
        `/common/empresas/${empresaSelecionada.id}/equipes`
      );
      const data = await response.data;
      setEquipesList(data);
    } catch (error) {
      console.error(error);
      notify('danger', 'Não foi possível carregar equipes');
    }
  }

  async function loadEstagios() {
    setEstagiosList([]);
    var response = null;

    if (funis && funis.length > 0) {
      response = await api.get(
        `common/empresas/${empresaSelecionada?.id}/estagios/getbyfunis`,
        {
          params: {
            funis,
          },
        }
      );
    } else {
      response = await api.get(
        `common/empresas/${empresaSelecionada?.id}/estagios`
      );
    }

    if (response && response.data) {
      setEstagiosList(response.data);
    }
  }

  async function loadFunis() {
    const response = await api.get(
      `common/empresas/${empresaSelecionada?.id}/funis`
    );
    if (response.data) {
      //response.data.unshift({ id: -1, nome: '- Todos -' })
      setFunisList(response.data);
    }
  }

  function handleMultipleSelect(target, state) {
    const array = Array.from(target.selectedOptions);
    state(array.map((item) => item.value));
  }

  return (
    <>
      <Filters
        title={title}
        onSearch={search}
        clearFilters={handleClearFilter}
        onFiltersClead={setMonitorClearFilters}
      >
        <CardBody>
          <Row className="mb-4">
            <Col xs="auto" lg="6" sm="12" md="12">
              <fieldset className="border p-2">
                <legend className="w-auto" style={{ margin: 0 }}>
                  <label className="form-control-label">Cadastro</label>
                </legend>
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example-number-input"
                      >
                        Início
                      </label>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-calendar-grid-58" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <ReactDatetime
                          inputProps={{
                            placeholder: 'Data inicial',
                            style: {
                              width: '100px',
                            },
                          }}
                          value={moment(dataCadastroInicial)}
                          dateFormat="DD/MM/YYYY"
                          timeFormat={false}
                          renderDay={(props, currentDate, selectedDate) => {
                            let classes = props.className;
                            if (
                              dataCadastroInicial &&
                              dataCadastroFinal &&
                              dataCadastroInicial._d + '' ===
                                currentDate._d + ''
                            ) {
                              classes += ' start-date';
                            } else if (
                              dataCadastroInicial &&
                              dataCadastroFinal &&
                              new Date(dataCadastroInicial._d + '') <
                                new Date(currentDate._d + '') &&
                              new Date(dataCadastroFinal._d + '') >
                                new Date(currentDate._d + '')
                            ) {
                              classes += ' middle-date';
                            } else if (
                              dataCadastroFinal &&
                              dataCadastroFinal._d + '' === currentDate._d + ''
                            ) {
                              classes += ' end-date';
                            }
                            return (
                              <td {...props} className={classes}>
                                {currentDate.date()}
                              </td>
                            );
                          }}
                          onChange={(e) => setDataCadastroInicial(e)}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example-number-input"
                      >
                        Fim
                      </label>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-calendar-grid-58" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <ReactDatetime
                          inputProps={{
                            placeholder: 'Data final',
                            style: {
                              width: '100px',
                            },
                          }}
                          style={{ width: '100px' }}
                          value={moment(dataCadastroFinal)}
                          dateFormat="DD/MM/YYYY"
                          timeFormat={false}
                          renderDay={(props, currentDate, selectedDate) => {
                            let classes = props.className;
                            if (
                              dataCadastroInicial &&
                              dataCadastroFinal &&
                              dataCadastroInicial._d + '' ===
                                currentDate._d + ''
                            ) {
                              classes += ' start-date';
                            } else if (
                              dataCadastroInicial &&
                              dataCadastroFinal &&
                              new Date(dataCadastroInicial._d + '') <
                                new Date(currentDate._d + '') &&
                              new Date(dataCadastroFinal._d + '') >
                                new Date(currentDate._d + '')
                            ) {
                              classes += ' middle-date';
                            } else if (
                              dataCadastroFinal &&
                              dataCadastroFinal._d + '' === currentDate._d + ''
                            ) {
                              classes += ' end-date';
                            }
                            return (
                              <td {...props} className={classes}>
                                {currentDate.date()}
                              </td>
                            );
                          }}
                          onChange={(e) => setDataCadastroFinal(e)}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </fieldset>
            </Col>
          </Row>
          <Row className="pt-4">
            <Col xs="12" lg="6" sm="12" md="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="example-number-input"
                >
                  Funil
                </label>
                <InputGroup className="input-group-alternative">
                  <Select2
                    multiple
                    onSelect={({ target }) =>
                      handleMultipleSelect(target, setFunis)
                    }
                    onUnselect={({ target }) =>
                      handleMultipleSelect(target, setFunis)
                    }
                    options={{
                      placeholder: 'Selecione...',
                    }}
                    value={funis}
                    data={funisList.map((item) => ({
                      id: item.id,
                      text: item.nome,
                    }))}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col xs="12" lg="6" sm="12" md="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="example-number-input"
                >
                  Estágio
                </label>
                <InputGroup className="input-group-alternative">
                  <Select2
                    multiple
                    onSelect={({ target }) =>
                      handleMultipleSelect(target, setEstagios)
                    }
                    onUnselect={({ target }) =>
                      handleMultipleSelect(target, setEstagios)
                    }
                    options={{
                      placeholder: 'Selecione...',
                    }}
                    value={estagios}
                    data={estagiosList.map((item) => ({
                      id: item.id,
                      text: item.nome,
                    }))}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row className="pb-4">
            <Col xs="12" lg="12" sm="12" md="12">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="example-number-input"
                >
                  Equipes
                </label>
                <InputGroup className="input-group-alternative">
                  <Select
                    closeMenuOnSelect={false}
                    placeholder="Selecione suas equipes..."
                    styles={{ container: () => ({ width: '100%' }) }}
                    isMulti
                    ref={equipesRef}
                    onChange={(values) => {
                      console.log({ values });
                      setEquipes(values.map((e) => e.value));
                    }}
                    options={equipesList.map((item) => ({
                      value: item.id,
                      label: item.nome,
                    }))}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col xs="12" lg="6" sm="12" md="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="example-number-input"
                >
                  Campanha
                </label>
                <InputGroup className="input-group-alternative">
                  <Select2
                    defaultValue="-1"
                    onSelect={(e) => loadAnuncios(e.target.value)}
                    options={{
                      placeholder: 'Selecione uma campanha...',
                    }}
                    value={campanha}
                    data={campanhas.map((item) => ({
                      id: item.id,
                      text: item.nome,
                    }))}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col xs="12" lg="6" sm="12" md="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="example-number-input"
                >
                  Anúncio
                </label>
                <InputGroup className="input-group-alternative">
                  <Select2
                    defaultValue="-1"
                    options={{
                      placeholder: 'Selecione uma anúncio...',
                    }}
                    onSelect={(e) => setAnuncio(e.target.value)}
                    value={anuncio}
                    data={anuncios.map((item) => ({
                      id: item.id,
                      text: item.nome,
                    }))}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Filters>
    </>
  );
};
