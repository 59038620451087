import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  CardText,
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Badge,
  Alert,
  Spinner,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';

import api from '../../../../../../services/api';
import { Canal } from '../../../../../../entities/Marketing';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { NovoCanalModal } from './modals/NovoCanalModal';
import { useGoogleLogin } from '@react-oauth/google';
import { CanalCard } from './components/cards';
import AuthContext from '../../../../../../contexts/Auth';
import QRCode from 'qrcode.react';
import { useWhatsappContext } from '../../../../../../contexts/Whatsapp';
import { IAccount } from '../../../../../marketing/Chat/types';

interface ClientInfoResponse {
  success: boolean;
  sessionInfo: {
    pushname: string;
    wid: {
      server: string;
      user: string;
      _serialized: string;
    };
    me: {
      server: string;
      user: string;
      _serialized: string;
    };
    platform: string;
  };
}

export interface CanalVenda extends Canal {
  color: string;
  icon: string;
  is_canal_contato: boolean;
}

export interface CanalContato {
  canal: CanalVenda;
  identificador: string;
  is_canal_contato: boolean;
}

export interface ModeloSite {
  id: number;
  link: string;
  tipo_modelo: 'vendedor' | 'indicacao';
  params_map: string;
}

interface ParametroSite {
  id: number;
  chave: string;
  valor: string;
  descricao: string;
  usuario_modelo_site_id: string;
}

export interface UsuarioModeloSite {
  id: string;
  user_empresa_id: number;
  modelo_site_id: number;
  parametroSite: ParametroSite[];
  site: ModeloSite;
}

export function Canais() {
  const { user } = useContext(AuthContext);
  const [canaisDeVenda, setCanaisDeVenda] = useState<CanalVenda[] | null>(null);
  const [canaisContato, setCanaisContato] = useState<CanalContato[] | null>(
    null
  );

  const [canalSelecionado, setCanalSelecionado] = useState<string | undefined>(
    undefined
  );
  const [isEmailSync, setIsEmailSync] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState<any>(null);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const modalOpenRef = useRef(false);

  const handleCloseModal = () => setModal(false);

  const [modalWhatsApp, setModalWhatsApp] = useState(false);
  const [QRCodeString, setQRCodeString] = useState('');
  const [compartilharConversas, setCompartilharConversas] = useState(false);
  const [sessionID, setSessionID] = useState('');
  const [isSessionActive, setIsSessionActive] = useState(false);
  const [userWhatsAppAccount, setUserWhatsAppAccount] =
    useState<IAccount | null>();

  const canaisDisponiveis = useMemo(() => {
    return canaisDeVenda?.filter(
      (canal) => !canaisContato?.some((contato) => canal.id == contato.canal.id)
    );
  }, [canaisContato, canaisDeVenda]);

  async function carregarCanaisDeVenda() {
    try {
      setIsLoading(true);
      const { data } = await api.get<CanalVenda[]>(`/marketing/canais`);

      setCanaisDeVenda(data.filter((canal) => canal.is_canal_contato));
    } catch (error) {
      console.error(
        'Houve um erro ao tentar carregar os canais de venda.',
        error
      );
    } finally {
      setIsLoading(false);
    }
  }

  async function carregarWhatsAppUserAccount() {
    const { data: accounts } = await api.get<IAccount[]>('/meta/all-accounts');
    if (accounts) {
      const foundAccount = accounts.find(
        (account) => account.user_id == user?.id && account.situacao == 'A'
      );

      if (foundAccount) {
        setUserWhatsAppAccount(foundAccount);
        checkSessionStatus(foundAccount.session_id);
        setCompartilharConversas(foundAccount.compartilhar_conversas);
      }
    }
  }

  function handleSaveNovoCanal({ canalSelecionado, identificador }: any) {
    createCanalContato(canalSelecionado, identificador);
  }

  useEffect(() => {
    carregarCanaisDeVenda();
    getCanaisContato();
    checkIfEmailIsSynchronized();
    carregarWhatsAppUserAccount();
  }, []);

  async function checkIfEmailIsSynchronized() {
    const { data } = await api.get<boolean>(
      `marketing/agendamentos/auth/google`
    );
    setIsEmailSync(data);
  }

  async function getCanaisContato() {
    try {
      setIsLoading(true);
      const { data } = await api.get(`/marketing/me/canais-contato`);

      setCanaisContato(data);
    } catch (error) {
      console.error(
        'Houve um erro ao tentar listar os canais de contato.',
        error
      );
    } finally {
      setIsLoading(false);
    }
  }

  async function createCanalContato(
    canalId: string | number,
    identificador: string
  ) {
    try {
      setIsLoading(true);
      const { data } = await api.post(`/marketing/me/canais-contato`, {
        identificador:
          canalId == '25' ? identificador.replace(/\D/g, '') : identificador,
        canal_id: canalId,
      });

      setAlert(
        <ReactBSAlert
          success
          style={{ display: 'block' }}
          title="Sucesso!"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="primary"
          confirmBtnText="Ok"
          btnSize=""
        >
          Canal de contato criado com sucesso!
        </ReactBSAlert>
      );

      getCanaisContato();
      checkIfEmailIsSynchronized();
      handleCloseModal();

      return data;
    } catch (error) {
      console.error(
        'Houve um erro ao tentar criar um canal de contato.',
        error
      );
    } finally {
      setIsLoading(false);
    }
  }

  function handleVisibilidadeCanalContato(
    canalId: string | number,
    isVisible: boolean
  ) {
    console.log({ canalId, isVisible });
    // TODO send to db
  }

  function handleDeleteCanalContato(canalId: string | number) {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: 'block' }}
        title="Tem certeza que deseja remover o contato?"
        onConfirm={() => deleteCanalContato(canalId)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="danger"
        confirmBtnText="Sim"
        btnSize=""
        showCancel
        focusCancelBtn
      />
    );
  }

  async function terminateWhatsAppSession(
    sessionId: string,
    accountId: string
  ) {
    await api.post(
      `/meta/terminate`,
      {
        session_id: sessionId,
        account_id: accountId,
      },
      { timeout: 15000 }
    );

    setAlert(null);
  }

  async function deleteCanalContato(canalId: string | number) {
    try {
      await api.delete(`/marketing/me/canais-contato/${canalId}`);

      if (canalId == 25 && userWhatsAppAccount) {
        const { id, session_id } = userWhatsAppAccount;
        terminateWhatsAppSession(session_id, id);
      }

      setAlert(
        <ReactBSAlert
          success
          style={{ display: 'block' }}
          title="Sucesso!"
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="primary"
          confirmBtnText="Ok"
          btnSize=""
        >
          Canal de contato removido com sucesso!
        </ReactBSAlert>
      );

      getCanaisContato();
    } catch (error) {
      console.error(
        'Houve um erro ao tentar remover um canal de contato.',
        error
      );
    }
  }

  const temCanais = canaisDisponiveis && canaisDisponiveis.length > 0;

  const formatPhoneNumber = (phoneNumber: string) => {
    if (phoneNumber.length <= 10) {
      return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(
        2,
        6
      )}-${phoneNumber.slice(6, 10)}`;
    } else {
      return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(
        2,
        7
      )}-${phoneNumber.slice(7, 11)}`;
    }
  };

  const formatSocialsLink = (text: string) => {
    return text.split('.com')[1];
  };

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    scope:
      'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.email',
    onSuccess: async (codeResponse) => {
      setIsLoading(true);
      const googleResponse = await api.post(
        'marketing/agendamentos/auth/google',
        {
          code: codeResponse.code,
        }
      );
      createCanalContato(12, googleResponse.data.userEmail);

      setIsEmailSync(true);
      setIsLoading(false);
      handleCloseModal();
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  function toggleWhatsAppModal() {
    modalOpenRef.current = !modalOpenRef.current;
    setModalWhatsApp((currentState) => !currentState);
  }

  function handleModalClose() {
    modalOpenRef.current = false;
    setModalWhatsApp(false);
    setQRCodeString('');
  }

  async function startSession() {
    try {
      const { data } = await api.get(`/meta/start`, {
        timeout: 10000,
      });
      setSessionID(data.id);

      console.log(
        '',
        `${new Date().toLocaleString()} - Sessão ${data.id} iniciada.`
      );
      return data;
    } catch (error) {
      window.alert(
        'Ocorreu um erro ao tentar gerar o QR code. Tente novamente em alguns minutos.'
      );
      console.error(error);
    }
  }

  async function fetchQRCode() {
    let isGenerating = true;
    let counter = 1;

    setIsLoading(true);

    console.log(`${new Date().toLocaleString()} - Iniciando uma sessão.`);
    const session = await startSession();

    while (
      isGenerating &&
      counter <= 10 &&
      session &&
      session.id &&
      modalOpenRef.current
    ) {
      try {
        console.log(
          `${new Date().toLocaleString()} - Tentando gerar um QR para a sessão. #${counter} tentativa.`
        );
        const { data } = await api.get(
          `/meta/qr/image?session_id=${session.id}`,
          {
            timeout: 5000, // Timeout de 5 segundos
          }
        );

        if (data.success) {
          setQRCodeString(data.qr);
          isGenerating = false;

          console.log(
            `${new Date().toLocaleString()} - QR Code gerado na #${counter} tentativa.`
          );
        } else if (counter === 10) {
          window.alert(
            'Ocorreu um erro ao tentar gerar o QR code. Tente novamente em alguns minutos.'
          );
        } else {
          console.log(
            `${new Date().toLocaleString()} - QR ainda não gerado, iniciando próxima tentativa em 5s.`
          );

          // Aguarda 5 segundos antes de tentar novamente
          await new Promise((resolve) => setTimeout(resolve, 5000));
          counter++;
        }
      } catch (error) {
        // @ts-ignore
        // if (error?.code === 'ECONNABORTED') {
        //   console.warn('Connection timed out:', error);
        //   window.alert(
        //     'Ocorreu um erro ao tentar gerar o QR code. Tente novamente em alguns minutos.'
        //   );
        //   break;
        // }

        console.log(
          `${new Date().toLocaleString()} - erro na requisição para gerar QR code, iniciando próxima tentativa em 5s.`
        );

        console.log('Erro na requisição:', error);
        counter++;

        // Aguarda 5 segundos antes de tentar novamente após um erro
        await new Promise((resolve) => setTimeout(resolve, 5000));
      }
    }

    setIsLoading(false);
  }

  async function checkSessionStatus(sessionId: string) {
    try {
      const { data } = await api.get(`/meta/status?session_id=${sessionId}`, {
        timeout: 10000,
      });
      if (data) {
        setIsSessionActive(data.success);
        return data.success;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function handleShareChatChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    try {
      const compartilharConversasCheck = event.target.checked;

      if (userWhatsAppAccount) {
        // api call para mudança do checkmark
        await api.put(`/meta/accounts/${userWhatsAppAccount.id}/share`, {
          compartilhar_conversas: compartilharConversasCheck,
        });
      }

      setCompartilharConversas(compartilharConversasCheck);
    } catch (error) {
      console.error(error);
    }
  }

  async function getClientInfo(sessionId: string) {
    try {
      console.log(
        `${new Date().toLocaleString()} - Buscando status da conexão com o WhatsApp.`
      );
      const {
        data: { success },
      } = await api.get(`/meta/status?session_id=${sessionId}`);

      if (success) {
        console.log(
          `${new Date().toLocaleString()} - Número conectado, buscando informações do número.`
        );
        const {
          data: { sessionInfo },
        } = await api.get<ClientInfoResponse>(
          `/meta/class-info?session_id=${sessionId}`
        );

        console.log(
          `${new Date().toLocaleString()} - Informações do número encontradas, salvando Account.`
        );

        const {
          wid: { user: numeroConectado },
        } = sessionInfo;

        const { pushname } = sessionInfo;
        // Se os dados da sessão existirem, registra a conta no sistema
        await api.post(`/meta/accounts/web`, {
          account: {
            verified_name: pushname,
            code_verification_status: 'VERIFIED',
            display_phone_number: numeroConectado,
            quality_rating: 'GREEN',
            id: numeroConectado,
            session_id: sessionID,
            compartilhar_conversas: compartilharConversas,
          },
        });

        // Exibe o alerta de sucesso e fecha o modal
        setAlert(
          <ReactBSAlert
            success
            style={{ display: 'block' }}
            title="Sucesso!"
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="primary"
            confirmBtnText="Ok"
            btnSize=""
          >
            Número sincronizado com sucesso!
          </ReactBSAlert>
        );
        setModalWhatsApp(false); // Fechar o modal
        setQRCodeString('');
        // Retorna verdadeiro para parar o polling
        return true;
      }
    } catch (error) {
      console.error('Erro ao obter informações do cliente:', error);
    }

    // Retorna falso para continuar o polling
    return false;
  }

  useEffect(() => {
    let pollingInterval: NodeJS.Timeout | undefined;

    if (QRCodeString && sessionID) {
      // Quando a imagem do QR Code for exibida, inicia o polling
      pollingInterval = setInterval(async () => {
        const isClientSynced = await getClientInfo(sessionID);

        if (isClientSynced) {
          // Se a conta for sincronizada, para o polling
          clearInterval(pollingInterval);
        }
      }, 3000);
    }

    // Limpa o polling se o componente for desmontado
    return () => {
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    };
  }, [QRCodeString, sessionID]);

  return (
    <>
      <Modal isOpen={modalWhatsApp} toggle={handleModalClose}>
        <ModalHeader>Meu WhatsApp</ModalHeader>
        <ModalBody>
          <Alert color="primary text-center">
            <strong>
              Atenção: utilize essa função para números comerciais.
            </strong>
            <br />
            Ao ler o QR code, o seu número comercial estará conectado
            exclusivamente ao seu perfil de usuário do Playnee. Ninguém além de
            você será capaz de ler as mensagens trocadas com seus clientes.
            Marque a opção “Compartilhar conversas com minha liderança“, caso
            queira que sua liderança possa visualizar tais informações.
          </Alert>

          {isLoading && (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ gap: '1rem' }}
            >
              <span>Gerando seu QR Code...</span>
              <Spinner color="primary" size="sm" />
            </div>
          )}
          {QRCodeString && (
            <div className="text-center mb-3">
              <QRCode size={256} value={QRCodeString} />
            </div>
          )}
          <FormGroup className="text-center mb-0">
            <Input
              id="shareChat"
              name="shareChat"
              type="checkbox"
              checked={compartilharConversas}
              onChange={handleShareChatChange}
            />
            <Label className="form-control-label" for="shareChat">
              Compartilhar conversas com a minha liderança
            </Label>
          </FormGroup>
        </ModalBody>
        {isSessionActive && (
          <Badge
            style={{ margin: '0 auto', width: 'min-content' }}
            color="success"
          >
            Conectado
          </Badge>
        )}
        {!isSessionActive && (
          <Badge
            style={{ margin: '0 auto', width: 'min-content' }}
            color="danger"
          >
            Desconectado
          </Badge>
        )}
        <ModalFooter>
          <Button outline onClick={handleModalClose}>
            Voltar
          </Button>

          {!isSessionActive && (
            <Button color="primary" disabled={isLoading} onClick={fetchQRCode}>
              Iniciar Sessão
            </Button>
          )}
          {isSessionActive && userWhatsAppAccount && (
            <Button
              color="danger"
              onClick={() =>
                terminateWhatsAppSession(
                  userWhatsAppAccount.session_id,
                  userWhatsAppAccount.id
                )
              }
            >
              Desconectar
            </Button>
          )}
        </ModalFooter>
      </Modal>

      {alert}
      {temCanais && (
        <NovoCanalModal
          isOpen={modal}
          onClose={handleCloseModal}
          onSave={handleSaveNovoCanal}
          canais={canaisDisponiveis}
          isLoading={isLoading}
          googleLogin={googleLogin}
        />
      )}

      <Row>
        <Col>
          <h2>Meus canais de contato</h2>
        </Col>
      </Row>
      <Row>
        {temCanais && (
          <Col xs={12} sm={6} md={4} lg={3}>
            <Card
              style={{
                background: '#eee',
                height: '400px',
                border: '2px dashed',
              }}
            >
              <CardBody
                className="d-flex align-items-center justify-content-center flex-column"
                onClick={toggle}
                role="button"
                style={{ cursor: 'pointer' }}
              >
                <CardText className="font-weight-bold">
                  <span>
                    <i className="fas fa-plus" />
                  </span>
                </CardText>
                <CardText className="font-weight-bold">Novo Canal</CardText>
              </CardBody>
            </Card>
          </Col>
        )}

        {canaisContato?.map((item) => (
          <CanalCard
            openWAModal={toggleWhatsAppModal}
            isConversasSync={isSessionActive}
            canal={item.canal}
            googleLogin={googleLogin}
            identificador={item.identificador}
            isEmailSync={isEmailSync}
            onChangePrivacy={handleVisibilidadeCanalContato}
            onDelete={handleDeleteCanalContato}
            key={item.canal.id}
            is_canal_contato
          />
        ))}
      </Row>
    </>
  );
}
