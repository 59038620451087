import React, { useContext, useEffect, useRef, useState } from 'react';
import Select2 from 'react-select2-wrapper';

import { Button, FormGroup, Modal } from 'reactstrap';
import AuthContext from '../../../../../../contexts/Auth';
import EmpresaContext from '../../../../../../contexts/Empresa';
import api from '../../../../../../services/api';
import { useWhatsappContext } from '../../../../../../contexts/Whatsapp';
import { User } from '../../../../../../entities/Security';

export default function EncaminharOportunidade({
  oportunidade,
  notify,
}) {
  const { auth } = useContext(AuthContext);
  const { empresaSelecionada } = useContext(EmpresaContext);
  const { atualizarContatos, selectContact } = useWhatsappContext();

  const [modalVisible, setModalVisible] = useState(false);
  const [colaboradorSelecionado, setColaboradorSelecionado] =
    useState(undefined);
  const [error, setError] = useState('');

  const colaboradorRef = useRef();

  const closeModal = () => setModalVisible(false);
  const openModal = () => setModalVisible(true);

  useEffect(() => {
    setError('');
  }, [colaboradorSelecionado]);

  function colaboradorOptions() {
    const option = {
      placeholder: 'Selecione um colaborador',
    };
    option['ajax'] = {
      delay: 250,
      url: `${process.env.REACT_APP_API_URL}/common/empresas/${empresaSelecionada?.id}/colaboradores?roles=comercial&roles=relacionamento&equipes[]=-1`,
      dataType: 'json',
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
      processResults: function (data: User[]) {
        return {
          results: data
            .map((item) => ({
              id: item.id,
              text: `${item.name}, ${item.email}`,
            }))
            .filter((item) => item.id != oportunidade.user_id),
        };
      },
    };
    return option;
  }

  async function handleEncaminhar() {
    if (!colaboradorSelecionado) return;
    try {
      await api.post(
        `/common/empresas/${empresaSelecionada?.id}/oportunidades/${oportunidade.id}/encaminhamentos`,
        {
          encaminar_para: colaboradorSelecionado,
        }
      );
      setModalVisible(false);
      selectContact(null);
      setColaboradorSelecionado(undefined);
      atualizarContatos();
    } catch (err) {
      if (err instanceof Error && err.hasOwnProperty('response')) {
        const response = (err as any).response;

        if (response?.data) {
          const data = response.data;
          if (data.message) {
            setError(data.message);
          } else {
            switch (response.status) {
              case 403:
                notify('danger', 'Você não pode realizar essa operação.');
                break;
              default:
                notify('danger', 'Não foi possível realizar alteração.');
                break;
            }
          }
        } else {
          notify('danger', 'Não foi possível realizar alteração.');
        }
      } else {
        notify('danger', 'Não foi possível realizar alteração.');
      }
    }
  }

  return (
    <>
      <Button size="sm" color="primary" onClick={openModal}>
        Encaminhar
      </Button>

      <Modal
        className="modal-dialog-centered modal-primary"
        size="md"
        isOpen={modalVisible}
        toggle={closeModal}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-notification">
            Encaminhar Oportunidade
          </h6>
        </div>
        <div className="modal-body">
          <div className="py-3 text-center">
            <i className="fas fa-question-circle ni-3x" />
            <h4 className="heading mt-4"></h4>
            <p>Para quem deseja encaminhar está oportunidade?</p>
            <FormGroup>
              <Select2
                ref={colaboradorRef}
                className="form-control"
                onSelect={(e) => setColaboradorSelecionado(e.target.value)}
                options={colaboradorOptions()}
                value={colaboradorSelecionado}
              />
              <small
                style={{
                  fontStyle: 'italic',
                }}
              >
                {error}
              </small>
            </FormGroup>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            className="text-white ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={closeModal}
          >
            Fechar
          </Button>
          <Button
            onClick={handleEncaminhar}
            className="btn-white"
            color="default"
            type="button"
          >
            Encaminhar
          </Button>
        </div>
      </Modal>
    </>
  );
}
